import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import BASE_URL from "../baseUrl/baseUrl";

const AdminSublistContent = (props) => {
    const navigate = useNavigate();
    const toast = useToast();
    const location = useLocation();
    const { state } = location;
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        axios
            .get(`${BASE_URL}/NavList/api/mainlists/${localStorage.getItem("id")}/sublists`)
            .then((res) => {
                setFlag(true);
                setData(res.data);
            });
    }, []);

    const editHeader = (e) => {
        navigate(`/admin/sublist/edit`, {
            state: e,
        });
    };

    const deleteHeaderContent = (id) => {
        axios
            .delete(`${BASE_URL}/NavList/api/mainlists/${localStorage.getItem("id")}/sublists/${id}`)
            .then((res) => {
                const filterData = data.filter((item) => item._id !== id);
                setData(filterData);
                toast({
                    title: "Success",
                    description: "Sublist content deleted successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
            })
            .catch((err) => {
                setError(true);
                setErrorMsg(err);
            });
    };

    const addHeader = () => {
        navigate("/admin/sublist/create");
    };

    const additem = (e) => {
        localStorage.setItem("subid", e);
        navigate("/admin/itemcontent");
    };

    // Pagination logic
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>Buy Home For Less | Admin Currency</title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>{state?.title} subList Content</h2>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "94%",
                            margin: "1%",
                        }}
                    >
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => addHeader()}
                        >
                            <span className="fa fa-plus"></span> Add subList Content
                        </button>
                    </div>
                    <div className="table-container-main">
                        <div className="table-container-child">
                            <table
                                className="table-striped table-bordered text-center"
                                style={{ width: "90%", margin: "1%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Title</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.length === 0 && flag ? (
                                        <tr className="text-center">
                                            <td colSpan="3">No Data</td>
                                        </tr>
                                    ) : currentData.length === 0 && flag === false ? (
                                        <tr className="text-center">
                                            <td colSpan="3">Loading ...</td>
                                        </tr>
                                    ) : (
                                        currentData?.map((res, key) => (
                                            <tr key={key}>
                                                <td>{startIndex + key + 1}</td>
                                                <td>{res.title}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => editHeader(res)}
                                                        style={{ borderRadius: "5px", marginRight: "10px" }}
                                                    >
                                                        <span className="fa fa-edit"></span>
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteHeaderContent(res._id)}
                                                        style={{ borderRadius: "5px", marginRight: "10px" }}
                                                    >
                                                        <span className="fa fa-trash"></span>
                                                        Delete
                                                    </button>
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={() => additem(res._id)}
                                                        style={{ borderRadius: "5px" }}
                                                    >
                                                        <span className="fa fa-plus"></span>
                                                        Add Page Item
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div>
                        {currentData.length === 0 ? (
                            null
                        ) : (
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSublistContent;
