import React from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Footer from "../layouts/Footerthree";
import Content from "../sections/homefour/Content";
import { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../baseUrl/baseUrl";

const Homefour = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/SEO/get`);
        if (response.data.result.title) {
          setName(response.data.result.title)
          setDescription(response.data.result.slug)

        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Homepage</title>
        <meta name={name} content={description} />
      </Helmet>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export default Homefour;
