import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import React from 'react';

function ConfirmModal({ data, isopen, setisopen, handleClick,setData }) {

  const closeModal = () => {
    setData([])
    setisopen(false);
  }

  return (
    <Modal isOpen={isopen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Submission</ModalHeader>
        <ModalBody>
          {data && data.length > 0 && (
            <>
              <h3>Some fields are empty</h3>
              <ul className='confirmmodal'>
                {data.map((field, index) => (
                  <li key={index} style={{color:"red"}}>{field} is empty</li>
                ))}
              </ul>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleClick}>Submit Anyway</Button>
          <Button onClick={closeModal} ml={3}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;

