import React, { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const AdminHeader = ({ collapsed, toggleCollapsed }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const logout = () => {
    localStorage.removeItem("userInfo");
    window.location.replace("/");
  };

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      window.location.replace("/");
    }
  }, [userInfo]);

  return (
    <div
      style={{
        backgroundColor: "#86848440",
        color: "white",
        display: "flex",
        padding: "10px 20px",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <div
        className="d-flex align-items-center mt-3 nav-1step"
        style={{
          fontFamily: "Times New Roman",
          fontSize: "40px",
          marginLeft: "20px"
        }}
      >
        <GiHamburgerMenu color="black" onClick={toggleCollapsed} />
        <div
          style={{
            fontSize: "30px",
            textAlign: "right",
            paddingRight: "15px",
            marginRight: "15px",
            fontFamily: "Times New Roman",
            width: "120px",
            borderRadius: "300px",
            height: "70px"
          }}>
          <img
            className="float-end text-light"
            width={80}
            height={65}
            src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
            alt="logo"
          />
        </div>
        <p className="text-black mb-0 fs-1">  Admin Panel </p>
      </div>
      <div className="col-md-2 col-lg-2 text-center float-end mt-3 nav-step2">
        <div style={{ display: 'flex', justifyContent: "end", marginRight: '40px' }}>

          <p
            onClick={logout}
            style={{
              backgroundColor: "black",
              padding: "5px 10px",
              borderRadius: "20px",
              cursor: "pointer",
              color: "white",
              width: "100%",
              marginLeft: "5px"
            }}
          >
            Log Out
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
