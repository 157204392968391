import React, { useState } from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import Edit from "../sections/submit-listing/Edit";

const AdminPropertyEdit = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Property Edit</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Property Edit</h2>
          </div>
          <Edit />
        </div>
      </div>
    </div>
  );
};

export default AdminPropertyEdit;
