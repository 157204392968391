import React from "react";
import Banner from "./Banner";
import Categories from "./Categories";
import Listings from "../hometwo/Listings";
import Blockcta from "../../layouts/Blockcta";
import Whyus from "../homethree/Whyus";
import Toplistings from "../home/Toplistings";
import Recentlistings from "./Recentlistings";
import Latestblog from "../home/Latestblog";
import Faqs from "./Faqs";
import Agentsslider from "../home/Agentsslider";
import Bluecta from "../../layouts/Bluecta";
import Testimonials from "../../pages/AdminTestimonials";
import Calculator from "../../layouts/Calculator";

const Content = () => {
  return (
    <div>
      <Banner />
      <div className="marginHomeContent">
        <Categories />
        <div className="section section-padding pt-0">
        </div>
        <div className="acr-footer footer-2">
          <Whyus />
        </div>

        <Recentlistings />
        <Blockcta />
        <Toplistings />
        <Latestblog />
        <Faqs />
        <Agentsslider />
        <div className="section pt-0">
          <Bluecta />
        </div>
        <Testimonials />
        <div className="col-12 myclass">
          <div>
            <h5>Mortgage Calculator</h5>
            <Calculator />
          </div>
          <div className="myImages">
            <img className="mortgageImage" src="https://real-state-store.s3.amazonaws.com/calculator.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
