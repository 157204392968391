import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import axios from "axios";
import BASE_URL from "../baseUrl/baseUrl";
import userImg from "../../assets/img/user_icon.png";
import categoryImg from "../../assets/img/catgory_icon.png";
import propertyImg from "../../assets/img/properties_icon-removebg-preview.png";

const AdminPanel = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false); // Moved collapsed state to AdminPanel

  const [item, setItem] = useState([
    { name: "Users", id: "users", count: 0, image: userImg },
    { name: "Categories", id: "category", count: 0, image: categoryImg },
    { name: "Properties", id: "subListings", count: 0, image: propertyImg },
  ]);

  const handleGetData = () => {
    axios
      .get(`${BASE_URL}/counts`)
      .then((res) => {
        const newItem = item.map((data) => ({
          ...data,
          count: res.data[data.id] || 0,
        }));
        setItem(newItem);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center", padding: "3%" }}>
            <h2>Welcome to Admin Page.</h2>
          </div>
          <div className="container">
            <div className="row">
              {item?.map((card, index) => (
                <div key={index} className="col-lg-4 col-md-6 sm-col-12 my-2">
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      height: "20vh",
                      position: "relative",
                      display: "flex",
                      borderColor: "gray",
                      backgroundColor: "lightGray",
                      padding: "10px",
                    }}
                    className="card"
                  >
                    <div style={{ textAlign: "left", marginTop: "32px" }}>
                      <span style={{ fontFamily: "sans-serif", fontSize: "20px", fontWeight: "bold" }}>
                        {card?.name}
                      </span>
                      <br />
                      <span style={{ fontSize: "25px", fontWeight: "bold" }}>{card?.count}</span>
                    </div>
                    <div style={{ position: "absolute", right: "10px", bottom: "10px" }}>
                      <img
                        style={{ width: "50px", height: "50px", marginBottom: "35px" }}
                        src={card?.image}
                        alt="Card icon"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
