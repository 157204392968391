import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [selectedChat, setSelectedChat] = useState([]);
  const [chats, setChats] = useState([]);
  const [mainlist, setMainlist] = useState("");
  const [sublist, setSublist] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUser(userInfo);
    if (!userInfo) navigate("/");
  }, [navigate]);
  return (
    <ChatContext.Provider
      value={{ user, setUser,mainlist, setMainlist, selectedChat, setSelectedChat, chats, setChats,sublist, setSublist }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
