import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Locationtab from "./Locationtab";
import { statusList } from "../../../data/common";
import convertToBase64 from "../../../helper/convert";
import bathoom from "../../../bathoom.png";
import bedroom from "../../../bedroom.png";

import { Container, Toast, useToast } from "@chakra-ui/react";
import { Select } from "antd";
import BASE_URL from "../../baseUrl/baseUrl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const { Option } = Select;
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "flex",
  flexDirection: "column",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "90px",
};

function Content() {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [disbtn, setDisBtn] = useState(false);
  const [rental, setRental] = useState(false);
  const [meetingroom, setMeetingroom] = useState(false);
  const [gameroom, setGameroom] = useState(false);
  const [maid, setMaid] = useState(false);
  const [library, setLibrary] = useState(false);
  const [jacuzzi, setJacuzzi] = useState(false);
  const [sauna, setSauna] = useState(false);
  const [swimmingpool, setSwimmingpool] = useState(false);
  const [guest, setGuest] = useState(false);
  const [gym, setGyms] = useState(false);
  const [laundryroom, setLaundryroom] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [nearTypeList, setNearTypeList] = useState([]);
  const [tabKey, setTabKey] = useState("tab1");
  const [files1, setfiles1] = useState();
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [Galleryimage, setGalleryimage] = useState(null);
  const [rentalPrice, setRentalPrice] = useState();
  const [countryCode, setCountryCode] = useState("");
  const [Floorimage, setFloorimage] = useState([]);
  const [Floorfiles, setFloorFiles] = useState([]);
  const [toggleLineId, setToggleLineId] = useState(true);
  const [toggleWechatId, setToggleWechatId] = useState(true);
  const [toggleAgentWhatsApp, setToggleAgentWhatsApp] = useState(true);
  const [toggleTelephone, setTelephone] = useState(true);
  const [currencymonthly, setCurrencymonthly] = useState("");
  const [currencyMin, setCurrencyMin] = useState("");
  const [currencyMax, setcurrencyMax] = useState("");
  const [currencyMaintainance, setcurrencyMaintainance] = useState("");
  const [AgentService, setAgentService] = useState("");
  const [roomNo, setroomNo] = useState("");
  const [tenniscourt, settenniscourt] = useState("");
  const [RoomFloor, setRoomFloor] = useState("");
  const [location, setLocation] = useState({
    address: "",
    country: "",
    city: "",
    provice: "",
    zipcode: "",
    // lat: "13.736717",
    // long: "100.523186",
    lat: "",
    long: "",
    tumbol: "",
    embedCode: "",
  });
  useEffect(() => {
    axios.get(`${BASE_URL}/admin/get-categories`).then((res) => {
      setTypeList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-currencies`).then((res) => {
      setCurrencyList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-features`).then((res) => {
      setFeatureList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-nearbytypes`).then((res) => {
      setNearTypeList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/property/${params.id}`).then((res) => {
      let data = res.data.result;
      console.log(data?.Location.country);
      // console.log(data?.Agent?.agentthumbnail);
      setDescription(data.BasicInformation.description);
      setMonthlyMortage(data.BasicInformation.mortage);
      setLand(data?.BasicInformation?.land);
      setName(data.BasicInformation.name);
      setCurrencymonthly(data.BasicInformation.mortagecurrency);
      setprevimage(data?.Agent?.agentthumbnail);
      setwechatId(data?.Agent?.WeChatID);
      setlineid(data?.Agent?.Line_Id);
      settenniscourt(data?.PropertyAmenity?.tenniscourt)
      const line = checkFieldsForNonNumeric(data?.Agent?.Line_Id);
      const whatsapp = checkFieldsForNonNumeric(data?.Agent?.WeChatID);
      const agentwhatsapp = checkFieldsForNonNumeric(data?.Agent?.agentwhatapp);
      const tel = checkFieldsForNonNumeric(data?.Agent?.agentTel);
      // const line = checkFieldsForNonNumeric(data?.Agent?.Line_Id);
      console.log(line, "line");
      setToggleLineId(!line);
      setToggleWechatId(!whatsapp);
      setToggleAgentWhatsApp(!agentwhatsapp);
      setTelephone(!tel);
      setAgentCountry(data?.Agent?.agentCountry);
      setAgentName(data?.Agent?.agentName);
      setAgentLastName(data?.Agent?.agentLastName);
      setAgentTel(data?.Agent?.agentTel);
      setCountryCode(data?.Agent?.countryCode);
      setAgentProvince(data?.Agent?.agentProvince);
      setAgentService(data?.Agent?.agentServices);
      setAgentWhatsApp(data?.Agent?.agentwhatapp);
      setcurrencyMax(data?.BasicInformation?.maxRangecurrency);
      setCurrencyMin(data?.BasicInformation?.minRangecurrency);
      setcurrencyMaintainance(data?.BasicInformation?.maintainanceFeecurrency);
      setMaxPrice(data?.BasicInformation?.maxRange);
      setMinPrice(data?.BasicInformation?.minRange);
      setElevator(data?.PropertyAmenity?.outdoorBath)
      setRentalPrice(
        data.BasicInformation.rentalPrice
          ? data.BasicInformation.rentalPrice
          : ""
      );
      setStatus(data?.BasicInformation?.status);
      setStatus(data?.BasicInformation?.listingType);
      setroomNo(data?.BasicInformation?.roomNo)
      setRoomFloor(data?.BasicInformation?.roomfloor)
      setVideofileName(data?.BasicInformation?.videofile);
      setListingType(data?.BasicInformation?.listingType);
      setMaintenanceFee(data?.BasicInformation?.maintainanceFee);
      setFurnished(data?.BasicInformation?.isFurnished);
      const receivedData = data?.BasicInformation?.FurnishedProperties;
      if (
        Array.isArray(receivedData) &&
        receivedData.length > 0 &&
        typeof receivedData[0] === "string"
      ) {
        const typesArray = receivedData[0].split(",");
        setFurnishedTypes(typesArray);
      } else if (typeof receivedData === "string") {
        const typesArray = receivedData.split(",");
        setFurnishedTypes(typesArray);
      } else {
        setFurnishedTypes();
      }
      // setFurnishedTypes(data?.BasicInformation?.FurnishedProperties)
      // if (typeof data.BasicInformation.status === 'string') {
      //   const values = data.BasicInformation.status.split(',').map((item) => ({
      //     label: item.trim(),
      //     value: item.trim()
      //   }));
      //   setStatus(values)
      // } else {
      //   console.error('status is not a string.');
      // }

      setType(data.BasicInformation.type);
      setpenthouse(data?.PropertyAmenity?.penthouse)
      setCurrency(data.BasicInformation.currency);
      setPrice(data.BasicInformation.price);
      setPeriod(data.BasicInformation.period);
      setSpace(data?.BasicInformation?.space);
      setVideo(data.BasicInformation.video);
      setThumbnailUrl(data.Gallery.file);
      setGalleryimage(data.Gallery.picture);
      setThumbnail(data.Gallery.file);
      setTitle(data.SEO.title);
      setSlug(data.SEO.slug);
      setPreviewImages(data.Gallery.picture);
      setFiles(data.Gallery.picture);
      setFloorFiles(data?.Floorimage);
      setfiles1(data.Gallery.picture);

      // setLocation({
      //   lat: data.Location.latitude,
      //   long: data.Location.longitude,
      //   country: data.Location.country,
      //   address: data.Location.address,
      //   city: data.Location.city,
      //   provice: data.Location.provice,
      //   zipcode: data.Location.zipcode,
      // });
      setLocation({
        lat: data.Location.latitude,
        long: data.Location.longitude,
        country: data.Location.country,
        address: data.Location.address,
        city: data.Location.city,
        provice:
          data.Location.provice === "undefined" ? "" : data?.Location.provice,
        zipcode: data.Location.zipcode,
        tumbol: data.Location.tumbol,
        embedCode: data.Location.embedCode,
      });
      let features = [];
      data.Features.map((item) => {
        return features.push(item._id);
      });
      setFeatures(features);
      setId(data.Details.id);
      setBeds(data.Details.beds);
      setBaths(data.Details.bathrooms);
      setCondition(data.Details.condition);
      setBuilt(data.Details.built);
      setNeighbor(data.Details.neighbor);
      setLiving(data.Details.living);
      setDining(data.Details.dining);
      setStory(data.Details.story);
      setParking(data.Details.parking);
      setLotsize(data.Details.lotsize);
      // setlandSize(data.Details.landSize);
      setView(data.Details.view);
      setLaundryroom(data.Details.laundryRoom);
      setMeetingroom(data.Details.meetingRoom);
      setGameroom(data.Details.gameRoom);
      setJacuzzi(data.Details.jacuzzi);
      setSauna(data.Details.sauna);
      setMaid(data.Details.maidRoom);
      setGuest(data.Details.guestRoom);
      setCommunityHall(data.PropertyAmenity.CommunityHall);
      setBBQArea(data.PropertyAmenity.BBQArea);
      setPartyHall(data.PropertyAmenity.PartyHall);
      setSwimmingpoolguest(data.PropertyAmenity.SwimmingPool);
      setGymguest(data.PropertyAmenity.Gym);
      setGuestParking(data.PropertyAmenity.GuestParking);
      setMinimart(data.PropertyAmenity.MiniMart);
      setLibraryguest(data.PropertyAmenity.Library);
      setPark(data.PropertyAmenity.Park);
      setPlayGround(data.PropertyAmenity.playGround);
      setAircondition(data.PropertyAmenity?.Fans);
      setsolarPower(data.PropertyAmenity?.solarPower);
      setseaview(data.PropertyAmenity?.seaview);
      sethotWater(data.PropertyAmenity.hotWater);
      setElectricity(data.PropertyAmenity.oceanFront);
      setpaidwater(data.PropertyAmenity.hotWater);
      let nearIds = [];
      let nears = [];
      data.Details.near.map((item) => {
        nearIds.push(item._id);
        return nears.push({
          key: item._id,
          neartype: item.neartype,
          name: item.name,
          distance: item.distance,
          isEdit: item.isEdit,
        });
      });
      setNearId(nearIds);
      setNears({ rows: nears });
    });
  }, [params.id]);

  const checkFieldsForNonNumeric = (fields) => {
    console.log(fields, "line");

    if (typeof fields !== "string") {
      return false;
    }

    const hasNonNumeric = /\D/.test(fields);

    console.log(hasNonNumeric, "hasNonNumeric"); // Log to check the value
    return hasNonNumeric;
  };
  // Error
  const [error, setError] = useState(undefined);
  const [fieldError, setFieldError] = useState(undefined);
  const [minFileError, setMinFileError] = useState(undefined);
  const [maxFileError, setMaxFileError] = useState(undefined);

  //  Basic Information
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState();
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(0);
  const [period, setPeriod] = useState("");
  const [space, setSpace] = useState(0);
  const [land, setLand] = useState(0);
  const [video, setVideo] = useState("");
  const [MonthlyMortage, setMonthlyMortage] = useState("");
  const [videofile, setVideofile] = useState("");
  const [videofileName, setVideofileName] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const [files, setFiles] = useState([]);
  const {
    getRootProps: getGalleryRootProps,
    getInputProps: getGalleryInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setFiles([]);
      // Check if there are any accepted files
      // console.log(acceptedFiles);
      if (acceptedFiles && acceptedFiles?.length > 0) {
        const thumbnailName = thumbnail?.name;
        const firstFileName = acceptedFiles[0]?.name;

        // Check if thumbnailName and firstFileName are the same
        if (thumbnailName && firstFileName && thumbnailName === firstFileName) {
          toast({
            title: "Error Occured",
            description: "Cannot upload thumbnail image",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "bottom-left",
          });
          console.error(
            "Error: Thumbnail name and first file name cannot be the same"
          );
        } else {
          // Set the files with previews if the names are different
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        }
      }
    },
  });
  const { getRootProps: getFloorRootProps, getInputProps: getFloorInputProps } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop: (acceptedFiles) => {
        setFloorFiles([]);
        if (acceptedFiles && acceptedFiles?.length > 0) {
          const thumbnailName = thumbnail?.name;
          const firstFileName = acceptedFiles[0]?.name;

          // Check if thumbnailName and firstFileName are the same
          if (
            thumbnailName &&
            firstFileName &&
            thumbnailName === firstFileName
          ) {
            toast({
              title: "Error Occured",
              description: "Cannot upload thumbnail image",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "bottom-left",
            });
            console.error(
              "Error: Thumbnail name and first file name cannot be the same"
            );
          } else {
            console.log(acceptedFiles);
            setFloorFiles(
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );
          }
        }
      },
    });
  const handleDeleteImage = (name) => {
    console.log(name);
    const updatedFiles = files.filter((file) => file.name !== name);
    // console.log("filesfiles", files);
    setFiles(updatedFiles);
  };
  const handleDeleteImage2 = (name) => {
    const updatedFiles = files.filter((file) => file !== name);
    setFiles(updatedFiles);
  };
  const handleChange2 = (value) => {
    setStatus(value);
  };
  // const thumbs = files?.map((file, key) =>
  //   file?.name ? (
  //     <div style={thumb} key={file?.name}>
  //       <div style={thumbInner}>
  //         <img
  //           src={file?.preview}
  //           style={img}
  //           alt="ThumbImage"
  //           // Revoke data uri after image is loaded
  //           onLoad={() => {
  //             URL.revokeObjectURL(file?.preview);
  //           }}
  //         />
  //       </div>
  //       <button style={{ backgroundColor: '#ADD8E6', }} onClick={() => handleDeleteImage(file?.name)}>Delete</button>
  //     </div>

  //   ) : (
  //     <div style={thumb} key={key}>
  //       <div style={thumbInner}>
  //         <img
  //           src={`${file}`}
  //           style={img}
  //           alt="ThumbImage"
  //           // Revoke data uri after image is loaded
  //           onLoad={() => {
  //             URL.revokeObjectURL(file.preview);
  //           }}
  //         />
  //       </div>
  //       <button style={{ backgroundColor: '#ADD8E6', }} onClick={() => handleDeleteImage2(file)}>Delete</button>
  //     </div>
  //   )
  // );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleThumbnailChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Promises = files.map((file) => convertToBase64(file));
    const base64Array = await Promise.all(base64Promises);

    setThumbnailUrl(base64Array);
    setThumbnail(files);
  };

  // Handle thumbnail deletion
  const handleDeleteThumbnail = (index) => {
    const updatedThumbnails = thumbnail.filter((_, i) => i !== index);
    const updatedThumbnailUrls = thumbnailUrl.filter((_, i) => i !== index);
    setThumbnail(updatedThumbnails);
    setThumbnailUrl(updatedThumbnailUrls);
  };

  const marginTopValue = thumbnailUrl?.length > 0 ? "13vh" : "0vh";

  //  Location

  const locationData = (data) => {
    console.log(data);
    setLocation(data);
  };

  //  Feature
  // const [features, setFeatures] = useState([]);
  const [features, setFeatures] = useState([]);
  const featuresData = (id) => {
    if (features.indexOf(id) !== -1) {
      features.splice(features.indexOf(id), 1);
    } else {
      setFeatures([...features, id]);
    }
  };

  // const featuresData = (id) => {
  //   if (features.indexOf(id) !== -1) {
  //     features.splice(features.indexOf(id), 0);
  //     document.getElementsByName(`feature${id}`).checked = false;
  //   } else {
  //     setFeatures([...features, id]);
  //     document.getElementsByName(`feature${id}`).checked = true;
  //   }
  // };

  // const feature = featureList.map((res, key) => (
  //   <div key={key} className="col-lg-4 col-md-6 col-sm-6">
  //     <label className="acr-listing-feature">
  //       <input
  //         type="checkbox"
  //         name={"feature" + res._id + ""}
  //         checked={features.indexOf(res._id) !== -1 ? true : false}
  //         onClick={() => featuresData(res._id)}
  //       />
  //       <i className="acr-feature-check fas fa-check" />
  //       <i style={{ textAlign: "-webkit-center" }}>
  //         <img
  //           className="acr-listing-feature-icon"
  //           src={`${BASE_URL}/${res.icon}`}
  //           style={{ marginBottom: "20px" }}
  //         />
  //       </i>
  //       {res.name}
  //     </label>
  //   </div>
  // ));
  //  Details
  const [id, setId] = useState("");
  const [beds, setBeds] = useState("");
  const [baths, setBaths] = useState("");
  const [condition, setCondition] = useState("");
  const [built, setBuilt] = useState("");
  const [neighbor, setNeighbor] = useState("");
  const [living, setLiving] = useState("");
  const [dining, setDining] = useState("");
  const [story, setStory] = useState(0);
  const [parking, setParking] = useState("");
  const [lotsize, setLotsize] = useState("");
  const [landSize, setlandSize] = useState("0");
  const [view, setView] = useState("");
  const [nears, setNears] = useState({ rows: [] });
  const [nearId, setNearId] = useState([]);
  const [envormentalValue, setEnvormentalValue] = useState("");
  const [agentName, setAgentName] = useState();
  const [agentLastName, setAgentLastName] = useState();
  const [agentTel, setAgentTel] = useState();
  const [agentwhatapp, setAgentWhatsApp] = useState();
  const [agentProvince, setAgentProvince] = useState();
  const [agentCountry, setAgentCountry] = useState();
  const [lineid, setlineid] = useState();
  const [wechatId, setwechatId] = useState();
  const [agentthumbnail, setagentThumbnail] = useState();
  const [agentthumbnailUrl, setagentThumbnailUrl] = useState();
  const [previmage, setprevimage] = useState();
  const [listingType, setListingType] = useState();
  const [maintenanceFee, setMaintenanceFee] = useState("");
  const [furnished, setFurnished] = useState("");
  const [furnishedTypes, setFurnishedTypes] = useState("");
  const [Aircondition, setAircondition] = useState("");
  const [solarPower, setsolarPower] = useState("");
  const [seaview, setseaview] = useState("");
  const [outdoorBath, setoutdoorBath] = useState("");
  const [hotWater, sethotWater] = useState("");
  // const [oceanFront, setoceanFront] = useState("");
  const [Electricity, setElectricity] = useState("");
  const [paidwater, setpaidwater] = useState("");
  const [penthouse, setpenthouse] = useState("");
  const [Elevator, setElevator] = useState("");
  const addRow = () => {
    const newRow = {
      neartype: nearTypeList[0]._id,
      name: "",
      distance: "",
      isEdit: false,
      key: "",
    };

    const selectRow = [...nears.rows];

    setNears({
      rows: [...selectRow, newRow],
    });
  };

  const enableEdit = (e, idx) => {
    e.preventDefault();
    const multy = [...nears.rows];
    if (e.target.innerHTML === "Edit") {
      e.target.innerHTML = "Save";
      multy[idx].isEdit = false;
    } else {
      e.target.innerHTML = "Edit";
      multy[idx].isEdit = true;
      axios
        .post(`${BASE_URL}/admin/near/create`, multy[idx])
        .then((res) => {
          multy[idx].key = res.data.result._id;
          setNearId([...nearId, res.data.result._id]);
        })
        .catch((err) => console.log(err));
    }

    setNears({
      rows: [...multy],
    });
  };
  const handleImageChange = (event) => {
    console.log(event.target.files);
    setfiles1(event.target.files);
    event.preventDefault();
    const selectedFiles =
      event.target.files || (event.dataTransfer && event.dataTransfer.files);
    if (!selectedFiles || selectedFiles?.length === 0) {
      return;
    }

    const previews = [];

    for (let i = 0; i < selectedFiles?.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();

      reader.onloadend = () => {
        previews.push(reader.result);
        if (previews?.length === selectedFiles?.length) {
          setPreviewImages(previews);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleImageChange(event);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeRow = (e, idx) => {
    e.preventDefault();
    const selectRow = [...nears.rows];

    if (selectRow[idx].isEdit) {
      axios
        .delete(`${BASE_URL}/admin/delete/near/${selectRow[idx].key}`)
        .then((res) => {
          nearId.splice(nearId.indexOf(res.data.result), 1);
        })
        .catch((err) => console.log(err));
    }
    selectRow.splice(idx, 1);
    setNears({
      rows: [...selectRow],
    });
  };

  const [bbqarea, setBBQArea] = useState("");
  const [partyhall, setPartyHall] = useState("");
  const [communityHall, setCommunityHall] = useState("");
  const [swimmingpoolguest, setSwimmingpoolguest] = useState("");
  const [guestgym, setGymguest] = useState("");
  const [guestParking, setGuestParking] = useState("");
  const [minimart, setMinimart] = useState("");
  const [libraryguest, setLibraryguest] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [park, setPark] = useState("");
  const [playGround, setPlayGround] = useState("");

  //  Validation
  const validate = () => {
    console.log(rentalPrice, price, status, MonthlyMortage);
    if (!description) {
      toast({
        title: "Error",
        description: "Insert Property Description",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    if (!name) {
      toast({
        title: "Error",
        description: "Insert Property Name",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    if (listingType !== "Rent" && !price) {
      toast({
        title: "Error",
        description: "Insert Property Price",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }
    if (
      listingType !== "Rent" &&
      (!MonthlyMortage || MonthlyMortage === undefined)
    ) {
      toast({
        title: "Error",
        description: "Insert Monthly Mortage Price",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    if (listingType == "Rent" && !rentalPrice) {
      toast({
        title: "Error",
        description: "Insert rental Price",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    // if (!space) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Space",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab1");
    //   return false;
    // }
    // if (!id) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property ID",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab5");
    //   return false;
    // }

    // if (!beds) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Beds",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab5");
    //   return false;
    // }

    if (
      listingType === "Rent" &&
      furnished === "yes" &&
      furnishedTypes?.length === 0
    ) {
      toast({
        title: "Error",
        description: "Insert Furnished Types",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    if (!maintenanceFee) {
      toast({
        title: "Error",
        description: "Insert Maintenance Fee",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }

    // if (!baths) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Bathrooms",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab5");
    //   return false;
    // }
    // if (!video && !videofileName) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Video file or URL",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab1");
    //   return false;
    // }
    // if (!thumbnail) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Thumbnail",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab2");
    //   return false;
    // }

    // if (files.length === 0) {
    //   toast({
    //     title: "Error",
    //     description: "Insert Property Gallery",
    //     status: "error",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab2");
    //   return false;
    // }

    if (files?.length > 15) {
      toast({
        title: "Error",
        description: "You can upload only 15 pictures",
        status: "warning",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab2");
      return false;
    }
    // if (Floorfiles?.length === 0) {
    //   toast({
    //     title: "Error",
    //     description: "Insert image",
    //     status: "warning",
    //     duration: 2000,
    //     variant: "left-accent",
    //     position: "top-right",
    //     isClosable: true,
    //   });
    //   setTabKey("tab8");
    //   return false;
    // }
    return true;
  };

  //  Submit
  const submitData = (e) => {
    console.log(location);
    e.preventDefault();
    if (!validate()) {
      return;
    }

    if (user === null) {
      alert("You need to login first");
    } else {
      // var a = "";
      // if (status[0].label) {
      //   const stringValue = status.map((item) => item.label).join(", ");
      //   a = stringValue;
      // } else {
      //   const stringValue = status.join(", ");
      //   // setStatus(stringValue);
      //   a = stringValue;
      // }

      const formData = new FormData();

      formData.append("name", name);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("type", type ? type : typeList[0].name);
      formData.append("currency", currency ? currency : currencyList[0].symbol);
      formData.append("price", price ? price : 0);
      formData.append("mortage", MonthlyMortage ? MonthlyMortage : 0);
      formData.append("mortagecurrency", currencymonthly ? currencymonthly : currencyList[0].symbol);
      formData.append("period", period ? period : "Monthly");
      formData.append("maintainanceFeecurrency", currencyMaintainance);
      formData.append("roomfloor", RoomFloor || '');
      formData.append("roomNo", roomNo || '');
      listingType === "Rent"
        ? formData.append("rentalPrice", rentalPrice ? rentalPrice : "0")
        : formData.append("rentalPrice", 0);
      formData.append("space", space ? space : "");
      formData.append("land", land ? land : "");
      formData.append("video", video);
      if (thumbnail?.length === 0) {
        thumbnailUrl.forEach((url, index) => {
          formData.append(`thumbnail`, url);
        });
      } else {
        thumbnail.forEach((file, index) => {
          formData.append(`thumbnail`, file);
        });
      }
      formData.append("videofile", videofile ? videofile : videofileName);

      for (let i = 0; i < files?.length; i++) {
        formData.append("picture", files[i]);
      }
      for (let i = 0; i < Floorfiles?.length; i++) {
        formData.append("Floorimage", Floorfiles[i]);
      }

      formData.append("title", title);
      formData.append("slug", slug);
      formData.append("latitude", location.lat);
      formData.append("longitude", location.long);
      formData.append("EmbedLink", location.embedCode);
      formData.append("tumbol", location.tumbol);
      formData.append("address", location.address);
      formData.append("country", location.country);
      formData.append("city", location.city);
      formData.append("provice", location.provice);
      formData.append("zipcode", location.zipcode);
      formData.append("features", features);
      formData.append("id", id);
      formData.append("beds", beds || "");
      formData.append("bathrooms", baths || "");
      formData.append("condition", condition);
      formData.append("built", built);
      formData.append("neighbor", neighbor);
      formData.append("living", living);
      formData.append("dining", dining);
      formData.append("story", story || "");
      formData.append("parking", parking );
      formData.append("lotsize", lotsize);
      formData.append("view", view);
      formData.append("landSize", landSize || 0);
      formData.append("near", nearId);
      formData.append("laundryRoom", laundryroom);
      formData.append("gameRoom", gameroom);
      formData.append("sauna", sauna);
      formData.append("maidRoom", maid);
      formData.append("guestRoom", guest);
      formData.append("meetingRoom", meetingroom);
      formData.append("jacuzzi", jacuzzi);
      formData.append("envormentValue", envormentalValue);
      formData.append("category", type ? type : typeList[0].name);
      formData.append("author", user._id);
      formData.append("BBQArea", bbqarea);
      formData.append("PartyHall", partyhall);
      formData.append("CommunityHall", communityHall);
      formData.append("SwimmingPool", swimmingpoolguest);
      formData.append("Gym", guestgym);
      formData.append("GuestParking", guestParking);
      formData.append("MiniMart", minimart);
      formData.append("Library", libraryguest);
      formData.append("Park", park);
      formData.append("playGround", playGround);
      formData.append("tenniscourt", tenniscourt);
      formData.append(
        "agentthumbnail",
        agentthumbnail ? agentthumbnail : previmage
      );
      formData.append("agentLastName", agentLastName);
      formData.append("agentName", agentName);
      formData.append("agentTel", agentTel);
      formData.append("countryCode", countryCode);
      formData.append("agentwhatapp", agentwhatapp);
      formData.append("agentProvince", agentProvince);
      formData.append("agentCountry", agentCountry);
      formData.append("agentServices", AgentService);
      formData.append("Line_Id", lineid);
      formData.append("WeChatID", wechatId);
      formData.append("minRange", minPrice);
      formData.append("maxRange", maxPrice);
      formData.append(
        "maxRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      formData.append(
        "minRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      formData.append("maintainanceFee", maintenanceFee);
      formData.append("isFurnished", furnished);
      formData.append("FurnishedProperties", furnishedTypes);
      formData.append("Fans", Aircondition);
      formData.append("solarPower", solarPower);
      formData.append("seaview", seaview);
      formData.append("hotWater", paidwater);
      formData.append("oceanFront", Electricity);
      formData.append("outdoorBath", Elevator);
      formData.append("penthouse", penthouse);
      // const formData = {
      //   name: name,
      //   description: description,
      //   status: a,
      //   type: type ? type : typeList[0].name,
      //   currency: currency ? currency : currencyList[0].symbol,
      //   price: price,
      //   period: period ? period : "Monthly",
      //   space: space,
      //   video: video,
      //   thumbnail: thumbnail,
      //   picture: files,
      //   lat: location.lat,
      //   long: location.long,
      //   address: location.address,
      //   country: location.country,
      //   city: location.city,
      //   provice: location.provice,
      //   zipcode: location.zipcode,
      //   features: features,
      //   id: id,
      //   beds: beds,
      //   bathrooms: baths,
      //   condition: condition,
      //   built: built,
      //   neighbor: neighbor,
      //   living: living,
      //   dining: dining,
      //   story: story,
      //   parking: parking,
      //   lotsize: lotsize,
      //   view: view,
      //   near: nearId,
      //   category: type ? type : typeList[0].name,
      //   author: user._id,
      // };
      console.log(formData);
      axios
        .put(
          `${BASE_URL}/admin/property/${params.id}/update`,
          formData
          // {
          //   headers: { "Content-Type": "multipart/form-data" },
          // }
        )
        .then((res) => {
          if (listingType === "Sell") {
            navigate("/admin/sellproperty");
          } else {
            navigate("/admin/rentproperty");
          }
          toast({
            title: "Success",
            description: "Edit Successfully",
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        })
        .catch((err) => {
          // const Msg = err.response.data.Msg;
          // if (Msg === "Please Fill Out All Feilds") {
          //   setError(true);
          //   setFieldError(true);
          //   setMinFileError(false);
          // } else if (Msg === "Please Fill Thumbnail Picture") {
          //   setError(true);
          //   setFieldError(false);
          //   setMinFileError(true);
          // } else if (Msg === "You can upload only 5 pictures") {
          //   setError(true);
          //   setFieldError(false);
          //   setMinFileError(false);
          //   setMaxFileError(true);
          // } else {
          console.log(err.response);
          // }
        });
    }
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
  };

  const checkingFunction = (buttonType) => {
    const countryCodes = [
      "93",
      "355",
      "213",
      "376",
      "244",
      "1-268",
      "54",
      "374",
      "61",
      "43",
      "994",
      "1-242",
      "973",
      "880",
      "1-246",
      "375",
      "32",
      "501",
      "229",
      "975",
      "591",
      "387",
      "267",
      "55",
      "673",
      "359",
      "226",
      "257",
      "238",
      "855",
      "237",
      "1",
      "236",
      "235",
      "56",
      "86",
      "57",
      "269",
      "243",
      "242",
      "506",
      "385",
      "53",
      "357",
      "420",
      "45",
      "253",
      "1-767",
      "1-809",
      "593",
      "20",
      "503",
      "240",
      "291",
      "372",
      "268",
      "251",
      "679",
      "358",
      "33",
      "241",
      "220",
      "995",
      "49",
      "233",
      "30",
      "1-473",
      "502",
      "224",
      "245",
      "592",
      "509",
      "504",
      "36",
      "354",
      "91",
      "62",
      "98",
      "964",
      "353",
      "972",
      "39",
      "1-876",
      "81",
      "962",
      "7",
      "254",
      "686",
      "850",
      "82",
      "383",
      "965",
      "996",
      "856",
      "371",
      "961",
      "266",
      "231",
      "218",
      "423",
      "370",
      "352",
      "261",
      "265",
      "60",
      "960",
      "223",
      "356",
      "692",
      "222",
      "230",
      "52",
      "691",
      "373",
      "377",
      "976",
      "382",
      "212",
      "258",
      "95",
      "264",
      "674",
      "977",
      "31",
      "64",
      "505",
      "227",
      "234",
      "389",
      "47",
      "968",
      "92",
      "680",
      "507",
      "675",
      "595",
      "51",
      "63",
      "48",
      "351",
      "974",
      "40",
      "7",
      "250",
      "1-869",
      "1-758",
      "1-784",
      "685",
      "378",
      "239",
      "966",
      "221",
      "381",
      "248",
      "232",
      "65",
      "421",
      "386",
      "677",
      "252",
      "27",
      "211",
      "34",
      "94",
      "249",
      "597",
      "46",
      "41",
      "963",
      "886",
      "992",
      "255",
      "66",
      "670",
      "228",
      "676",
      "1-868",
      "216",
      "90",
      "993",
      "688",
      "256",
      "380",
      "971",
      "44",
      "1",
      "598",
      "998",
      "678",
      "39",
      "58",
      "84",
      "967",
      "260",
      "263",
    ];

    for (const code of countryCodes) {
      if (buttonType.startsWith(code)) {
        const number = buttonType.slice(code.length).trim(); // Extract number after the country code
        console.log(code);
        console.log(number);

        setCountryCode(code);
        setAgentTel(number);
        return;
      }
    }

    console.error("No matching country code found");
    setCountryCode("");
    setAgentTel("");
  };

  // const [jacuzzi, setLaund] = useState(false);
  // const handleChange = (value) => {
  //   setStatus(value);
  //   const hasRental = value.includes("Rental");
  //   setRental(hasRental);
  // };
  if (!status) return;

  const handlefile = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setVideofile(file);
    }
  };

  const handleFurnishedTypesChange = (value) => {
    setFurnishedTypes(value);
  };

  return (
    <div className="section">
      <Container maxW="80%">
        <div className="row">
          <Tab.Container defaultActiveKey={tabKey} activeKey={tabKey}>
            {/* Tabs Start */}
            <div className="col-md-4">
              <Nav variant="tabs" className="nav nav-tabs tab-cards">
                <Nav.Item>
                  <Nav.Link eventKey="tab1" onClick={() => setTabKey("tab1")}>
                    <span>01</span> Basic Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2" onClick={() => setTabKey("tab2")}>
                    <span>02</span> Gallery
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3" onClick={() => setTabKey("tab3")}>
                    <span>03</span> Location
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab4" onClick={() => setTabKey("tab4")}>
                    <span>04</span> Property Amenity
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab5" onClick={() => setTabKey("tab5")}>
                    <span>05</span> Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab6" onClick={() => setTabKey("tab6")}>
                    <span>06</span> Agent Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab7" onClick={() => setTabKey("tab7")}>
                    <span>07</span> Seo
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab8" onClick={() => setTabKey("tab8")}>
                    <span>08</span> Floor Plan
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {/* Tabs End */}
            {/* Tab Content Start */}
            <div className="col-md-8">
              <form onSubmit={(e) => e.preventDefault()}>
                <Tab.Content className="m-0">
                  <Tab.Pane eventKey="tab1">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Property Description</label>
                        <textarea
                          name="content"
                          rows={4}
                          className="form-control"
                          placeholder="Property Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Property Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Name"
                          required
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Property Status</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Status"
                          disabled
                          name="name"
                          value={status}
                          // onChange={(e) => setName(e.target.value)}
                        />
                        {/* <Select
                          style={{ width: "100%" }}
                          placeholder="PropertyStatus"
                          value={status}
                          onChange={handleChange2}
                        >
                          {statusList?.map((status) => (
                            <Select.Option key={status.value} value={status.value}>
                              {status.label}
                            </Select.Option>
                          ))}
                        </Select> */}
                      </div>
                      <div className="col-md-6">
                        <label>Property Type</label>
                        <select
                          className="form-control"
                          name="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          {typeList.map((res, key) => (
                            <option key={key} value={res.name}>
                              {res.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {console.log(status)}
                      {listingType !== "Rent" ? (
                        <div className="col-md-6 form-group">
                          <label>Sale Price</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select
                                className="form-control"
                                name="type"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                {currencyList.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Property Price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-md-6">
                            <label>Rental Period</label>
                            <select
                              className="form-control"
                              name="period"
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                            >
                              <option value="Monthly">Monthly</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Daily">Daily</option>
                            </select>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>Rental Price</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <select
                                  className="form-control"
                                  name="type"
                                  value={currency}
                                  onChange={(e) => setCurrency(e.target.value)}
                                >
                                  {currencyList.map((res, key) => (
                                    <option key={key} value={res.symbol}>
                                      {res.symbol}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <input
                                className="form-control"
                                name="rentalPrice"
                                value={rentalPrice}
                                onChange={(e) => setRentalPrice(e.target.value)}
                                type="number"
                              />
                            </div>
                          </div>
                        </>
                      )}
                        {(type == "Condo" || type == "Apartment") && (
                        <div
                          className={`col-md-6 form-group ${
                            listingType === "Sell" ? "mt-0" : "mt-0"
                          }`}
                        >
                          <label>Room#</label>
                          <div className="input-group">
                            <input
                              className="form-control"
                              name="period"
                              type="number"
                              value={roomNo}
                              onChange={(e) => setroomNo(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                      {
                          (type == "Condo" || type == "Apartment") && (
                            <div className="col-md-6 form-group ">
                            <label>Room Floor</label>
                            <input
                              type="number"
                              className="form-control"
                              name="buildstory"
                              value={RoomFloor}
                              onChange={(e) => setRoomFloor(e.target.value)}
                            />
                          </div>
                          )}
                      <div className="col-md-6 form-group">
                        <label>Min-Price</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMin}
                              onChange={(e) => setCurrencyMin(e.target.value)}
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Max-Price</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMin}
                              onChange={(e) => setCurrencyMin(e.target.value)}
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>
                      {listingType !== "Rent" ? (
                        <div className="col-md-6 form-group">
                          <label>Monthly Mortage</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select
                                className="form-control"
                                name="type"
                                value={currencymonthly}
                                onChange={(e) =>
                                  setCurrencymonthly(e.target.value)
                                }
                              >
                                {currencyList.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Monthly Mortage"
                              value={MonthlyMortage}
                              onChange={(e) =>
                                setMonthlyMortage(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {listingType === "Rent" ? (
                        <>
                          <div className="col-md-6">
                            <label>Furnished</label>
                            <select
                              className="form-control"
                              name="furnished"
                              value={furnished}
                              onChange={(e) => setFurnished(e.target.value)}
                            >
                              <option>Select the value</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          {furnished === "yes" && (
                            <div className="col-md-6">
                              <label>Type of Furnish</label>
                              <Select
                                mode="multiple"
                                placeholder="Select an option"
                                style={{ width: "100%" }}
                                onChange={handleFurnishedTypesChange}
                                value={furnishedTypes}
                              >
                                <Option value="Stove">Stove</Option>
                                <Option value="Refrigerator">
                                  Refrigerator
                                </Option>
                                <Option value="Air Condition">
                                  Air Condition
                                </Option>
                                <Option value="Bed">Bed</Option>
                                <Option value="TV">TV</Option>
                              </Select>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {status == "For Rent" ? (
                        <>
                          <div className="col-md-6">
                            <label>Rental Period</label>
                            <select
                              className="form-control"
                              name="period"
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                            >
                              <option value="Monthly">Monthly</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Daily">Daily</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label>Rental Price</label>
                            <input
                              className="form-control"
                              name="period"
                              value={rentalPrice}
                              onChange={(e) => setRentalPrice(e.target.value)}
                              type="number"
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )} */}
                        {(type == "Condo" ||
                        type == "Apartment" ||
                        type == "Flats") && (
                        <div
                          className={`col-md-6 form-group ${
                            listingType === "Sell" ? "mt-0" : "mt-0"
                          }`}
                        >
                          <label>ROOM AREA SQM (Optional)</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Room Area"
                            name="space"
                            value={space}
                            onChange={(e) => setSpace(e.target.value)}
                          />
                        </div>
                      )}
                      <div className="col-md-6 form-group">
                        <label>Land Area (SQM)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Land Area (Sqm)"
                          name="space"
                          value={land}
                          onChange={(e) => setLand(e.target.value)}
                        />
                        <div
                          style={{
                            display: "flex",
                            width: "60px",
                            marginTop: "10px",
                          }}
                        >
                          <img src={bathoom} />
                          <img src={bedroom} />
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Maintainance Fee</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMaintainance}
                              onChange={(e) =>
                                setcurrencyMaintainance(e.target.value)
                              }
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={maintenanceFee}
                            onChange={(e) => setMaintenanceFee(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 form-group">
                        <label>Property Video</label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          placeholder="Property Video URL"
                          name="video"
                          value={video}
                          onChange={(e) => setVideo(e.target.value)}
                        />
                        <input
                          type="file"
                          className="form-control"
                          placeholder="PropertyVideo"
                          name="videofile"
                          accept="video/*"
                          // value={video}
                          onChange={handlefile}
                        />
                        <p>{!videofile ? videofileName : ""}</p>
                      </div>
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab2"
                            onClick={() => setTabKey("tab2")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    <div className="form-group row">
                      <label>Property Thumbnail</label>
                      <div className="custom-file col-md-4 col-lg-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="propertyThumbnail"
                          multiple
                          onChange={handleThumbnailChange}
                          style={{ display: "none" }}
                        />
                        <label
                          className="custom-file-label cursor-pointer"
                          htmlFor="propertyThumbnail"
                        >
                          Choose File
                        </label>
                      </div>
                    </div>

                    <div className="img-preview">
                      {thumbnailUrl?.length > 0
                        ? thumbnailUrl.map((url, index) => (
                            <div key={index}>
                              <img
                                src={url}
                                alt={`Thumbnail ${index + 1}`}
                                className="col-lg-3 col-md-3 mt-2"
                                style={{ width: "100px", aspectRatio: "3/3" }}
                              />
                              <button
                                onClick={() => handleDeleteThumbnail(index)}
                                style={{
                                  backgroundColor: "#ADD8E6",
                                  height: "25%",
                                  width: "100%",
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          ))
                        : thumbnailUrl && (
                            <img
                              className="col-lg-3 col-md-3"
                              src={thumbnailUrl}
                              style={{ width: "100px", aspectRatio: "3/3" }}
                            />
                          )}
                    </div>
                    <div
                      className="form-group mt-4"
                      style={{ marginTop: marginTopValue }}
                    >
                      <label>Property Gallery</label>
                      <div
                        {...getGalleryRootProps({
                          className: "dropzone cursor-pointer",
                        })}
                      >
                        <input {...getGalleryInputProps()} multiple />
                        <div className="dropzone-msg dz-message needsclick">
                          <i className="fas fa-cloud-upload-alt" />
                          <h5 className="dropzone-msg-title">
                            Drop files here or click to upload.
                          </h5>
                          <span className="dropzone-msg-desc">
                            This is just a demo dropzone. Selected files are{" "}
                            <strong>not</strong> actually uploaded.
                          </span>
                        </div>
                      </div>
                      <aside
                        style={{ display: "flex" }}
                        className={thumbsContainer}
                      >
                        {/* {thumbs} */}
                      </aside>

                      <span className="acr-form-notice">
                        *You can upload up to 15 images for your listing
                      </span>
                      <span className="acr-form-notice">
                        *Listing images should be at least 620x480 in dimensions
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      {files?.length > 0 && files[0]?.preview
                        ? files.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                marginRight: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                key={index}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginBottom: "20px",
                                }}
                                src={image?.preview}
                                alt={image}
                              />
                            </div>
                          ))
                        : files.map((img, index) => (
                            <img
                              key={index}
                              style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "20px",
                                marginRight: "20px",
                              }}
                              src={img}
                              alt={img}
                            />
                          ))}
                    </div>
                    <div className="w-25">
                      <button className="btn btn-primary px-2 py-2 w-100">
                        <Nav.Link
                          eventKey="tab3"
                          onClick={() => setTabKey("tab3")}
                        >
                          Save
                        </Nav.Link>
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab3">
                    {/* {console.log("object",location)} */}
                    <Locationtab data={location} locationData={locationData} />
                    <div className="w-25">
                      <button className="btn btn-primary px-2 py-2 w-100">
                        <Nav.Link
                          eventKey="tab4"
                          onClick={() => setTabKey("tab4")}
                        >
                          Save
                        </Nav.Link>
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    <div className="row">
                      {/* {featureList.map((res, key) => (
                        <div key={key} className="col-lg-4 col-md-6 col-sm-6">
                          <label className="acr-listing-feature">
                            <input
                              type="checkbox"
                              name={"feature" + res._id + ""}
                              onClick={() => featuresData(res._id)}
                            />
                            <i className="acr-feature-check fas fa-check" />
                            <i style={{ textAlign: "-webkit-center" }}>
                              <img
                                className="acr-listing-feature-icon"
                                src={`${res.icon}`}
                                alt="ThumbImage"
                                style={{ marginBottom: "20px", width: '200px', height: '200px' }}
                              />
                            </i>
                            {res.name}
                          </label>
                        </div>
                      ))} */}

                      <div className="col-md-6 form-group">
                        <label>BBQ Area</label>
                        <select
                          className="form-control"
                          name="bbqarea"
                          value={bbqarea}
                          onChange={(e) => setBBQArea(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Party Hall</label>
                        <select
                          className="form-control"
                          name="partyhall"
                          value={partyhall}
                          onChange={(e) => setPartyHall(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Community Hall</label>
                        <select
                          className="form-control"
                          name="community hall"
                          value={communityHall}
                          onChange={(e) => setCommunityHall(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Swimming Pool</label>
                        <select
                          className="form-control"
                          name="swimmingpools"
                          value={swimmingpoolguest}
                          onChange={(e) => setSwimmingpoolguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Gym</label>
                        <select
                          className="form-control"
                          name="guestgym"
                          value={guestgym}
                          onChange={(e) => setGymguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Guest Parking</label>
                        <select
                          className="form-control"
                          name="guestParking"
                          value={guestParking}
                          onChange={(e) => setGuestParking(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Mini Mart</label>
                        <select
                          className="form-control"
                          name="minimart"
                          value={minimart}
                          onChange={(e) => setMinimart(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Library</label>
                        <select
                          className="form-control"
                          name="libraryguest"
                          value={libraryguest}
                          onChange={(e) => setLibraryguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Park</label>
                        <select
                          className="form-control"
                          name="park"
                          value={park}
                          onChange={(e) => setPark(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Play Ground</label>
                        <select
                          className="form-control"
                          name="playGround"
                          value={playGround}
                          onChange={(e) => setPlayGround(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Tennis Court</label>
                        <select
                          className="form-control"
                          name="tenniscourt"
                          value={tenniscourt}
                          onChange={(e) => settenniscourt(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"Air Condition"}</label>
                        <select
                          className="form-control"
                          name="Fans"
                          value={Aircondition}
                          onChange={(e) => setAircondition(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="">Select value</option>
                          <option value="true">{"Yes"}</option>
                          <option value="false">{"No"}</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"SolarPower"}</label>
                        <select
                          className="form-control"
                          name="solarPower"
                          value={solarPower}
                          onChange={(e) => setsolarPower(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">{"Yes"}</option>
                          <option value="false">{"No"}</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"view"}</label>
                        <select
                          className="form-control"
                          name="seaview"
                          value={seaview}
                          onChange={(e) => setseaview(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">{"Yes"}</option>
                          <option value="false">{"No"}</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"Elevator"}</label>
                        <select
                          className="form-control"
                          name="Elevator"
                          value={Elevator}
                          onChange={(e) => setElevator(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">{"Yes"}</option>
                          <option value="false">{"No"}</option>
                        </select>
                      </div>
                      {
                        listingType == "Rent" && (
                          <div className="col-md-6 form-group">
                          <label>Paid Electricity</label>
                          <select
                            className="form-control"
                            name="Electricity"
                            value={Electricity}
                            onChange={(e) => setElectricity(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        )
                       }
                     {
                          listingType == "Rent"&& (
                            <div className="col-md-6 form-group">
                          <label>Paid water</label>
                          <select
                            className="form-control"
                            name="paidwater"
                            value={paidwater}
                            onChange={(e) => setpaidwater(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                          )
                        }
                      <div className="col-md-6 form-group">
                          <label>Pent House</label>
                          <select
                            className="form-control"
                            name="PentHouse"
                            value={penthouse}
                            onChange={(e) => setpenthouse(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                    </div>
                    <div className="w-25">
                      <button className="btn btn-primary px-2 py-2 w-100">
                        <Nav.Link
                          eventKey="tab5"
                          onClick={() => setTabKey("tab5")}
                        >
                          Save
                        </Nav.Link>
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab5">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Property ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property ID"
                          name="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Beds</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of Beds"
                          name="beds"
                          value={beds}
                          onChange={(e) => setBeds(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Bathrooms</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of Bathrooms"
                          name="baths"
                          value={baths}
                          onChange={(e) => setBaths(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Condition</label>
                        <select
                          className="form-control"
                          onChange={(e) => setCondition(e.target.value)}
                          value={condition}
                        >
                          <option value="">Select Condition</option>
                          <option value="Used">Used</option>
                          <option value="New">New</option>
                          <option value="Satisfactory">Satisfactory</option>
                          <option value="Fixer upper">Fixer upper</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Year Built</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Year Built"
                          name="built"
                          value={built}
                          onChange={(e) => setBuilt(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Neighborhood</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Neighborhood"
                          name="neighborhood"
                          value={neighbor}
                          onChange={(e) => setNeighbor(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Living Room</label>
                        {/* <select
                          className="form-control"
                          name="livingRoom"
                          value={living}
                          onChange={(e) => setLiving(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select> */}
                        <input
                          type="number"
                          className="form-control"
                          name="livingRoom"
                          value={living}
                          onChange={(e) => setLiving(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Dining Room</label>
                        {/* <select
                          className="form-control"
                          name="diningRoom"
                          value={dining}
                          onChange={(e) => setDining(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select> */}
                        <input
                          type="number"
                          className="form-control"
                          name="diningRoom"
                          value={dining}
                          onChange={(e) => setDining(e.target.value)}
                        ></input>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Gyms</label>
                        <select
                          className="form-control"
                          name="gyms"
                          value={gym}
                          onChange={(e) => setGyms(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div> */}
                      <div className="col-md-6 form-group">
                        <label>Laundry Room</label>
                        <select
                          className="form-control"
                          name="laundryroom"
                          value={laundryroom}
                          onChange={(e) => setLaundryroom(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Meeting room</label>
                        <select
                          className="form-control"
                          name="meetingroom"
                          value={meetingroom}
                          onChange={(e) => setMeetingroom(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Game Room</label>
                        <select
                          className="form-control"
                          name="gameroom"
                          value={gameroom}
                          onChange={(e) => setGameroom(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Library</label>
                        <select
                          className="form-control"
                          name="library"
                          value={library}
                          onChange={(e) => setLibrary(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div> */}
                      {/* <div className="col-md-6 form-group">
                        <label>Swimming Pool</label>
                        <select
                          className="form-control"
                          name="swimmingpool"
                          value={swimmingpool}
                          onChange={(e) => setSwimmingpool(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div> */}
                      <div className="col-md-6 form-group">
                        <label>Jacuzzi</label>
                        <select
                          className="form-control"
                          name="jacuzzi"
                          value={jacuzzi}
                          onChange={(e) => setJacuzzi(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Sauna</label>
                        <select
                          className="form-control"
                          name="sauna"
                          value={sauna}
                          onChange={(e) => setSauna(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Maid Room</label>
                        <select
                          className="form-control"
                          name="maidroom"
                          value={maid}
                          onChange={(e) => setMaid(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Guest room</label>
                        <select
                          className="form-control"
                          name="guestroom"
                          value={guest}
                          onChange={(e) => setGuest(e.target.value)}
                        >
                          <option value="">Select option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Building Floor</label>
                        <input
                          type="number"
                          className="form-control"
                          name="buildstory"
                          value={story}
                          onChange={(e) => setStory(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Parking</label>
                        <select
                          onChange={(e) => setParking(e.target.value)}
                          className="form-control"
                          value={parking}
                        >
                          <option value="">select Parking</option>
                          <option value="1-2 Car Garage">1-2 Car Garage</option>
                          <option value="3 Car Garage">3 Car Garage</option>
                          <option value="4 Car Garage">4 Car Garage</option>
                          <option value="5 Car Garage">5 Car Garage</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Room Size</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Room Size"
                          name="lotsize"
                          value={lotsize}
                          onChange={(e) => setLotsize(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>View</label>
                        <select
                          onChange={(e) => setView(e.target.value)}
                          className="form-control"
                          value={view}
                        >
                          <option value="">Select View</option>
                          <option value="Beach">Beach</option>
                          <option value="Mountain">Mountain</option>
                          <option value="Lake">Lake</option>
                          <option value="Cities Life">Cities Life</option>
                          <option value="Rural hills">Rural hills</option>
                        </select>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Land Size</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Land Size"
                          name="landSize"
                          value={landSize}
                          onChange={(e) => setlandSize(e.target.value)}
                        />
                      </div> */}
                    </div>
                    <div className="row" style={{ width: "100%" }}>
                      <label>Enter the Nearby </label>
                      <table
                        className="table table-bordered text-center"
                        style={{ margin: "0px 20px" }}
                      >
                        <thead>
                          <tr className="roof">
                            <th className="col-3"> Select Type </th>
                            <th className="col-4"> Name</th>
                            <th className="col-2"> Distance (km)</th>
                            <th className="col-3">
                              <button
                                className="btn btn-success"
                                onClick={() => addRow()}
                              >
                                + Add Row
                              </button>{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {nears.rows.map((item, idx) => (
                            <>
                              <tr key={idx}>
                                <td>
                                  {/* <select
                                  className="form-control"
                                  value={item.type}
                                  name="neartype"
                                  disabled={nears.rows[idx].isEdit}
                                  onChange={(e) => {
                                    const multy = [...nears.rows];
                                    multy[idx].neartype = e.target.value;
                                    setNears({
                                      rows: [...multy],
                                    });
                                  }}
                                >
                                  {nearTypeList.map((res, key) => (
                                    <option key={key} value={res._id}>
                                      {res.name}
                                    </option>
                                  ))}
                                </select> */}
                                  <select
                                    className="form-control"
                                    value={item.type}
                                    name="type"
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].neartype = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  >
                                    {nearTypeList.map((res, key) => (
                                      <option key={key} value={res._id}>
                                        {res.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={item.name}
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].name = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="distance"
                                    value={item.distance}
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].distance = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  />
                                </td>
                                <td
                                  className="td-valid"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="btn btn-primary mr-2 mb-1"
                                    onClick={(e) => {
                                      enableEdit(e, idx);
                                    }}
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-danger mb-1"
                                    onClick={(e) => removeRow(e, idx)}
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                              <td>
                                {nears.rows[0]?.neartype ==
                                  "64db14b79e7ad116420e7a18" && (
                                  <select
                                    defaultValue={envormentalValue}
                                    onChange={(e) =>
                                      setEnvormentalValue(e.target.value)
                                    }
                                    className="form-control"
                                  >
                                    {nearTypeList[5].Sublist.map((res, key) => (
                                      <option key={key} value={res}>
                                        {res}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </td>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="form-group"
                      style={{ marginTop: "30px" }}
                    ></div>
                    <div className="w-25">
                      <button className="btn btn-primary px-2 py-2 w-100">
                        <Nav.Link
                          eventKey="tab6"
                          onClick={() => setTabKey("tab6")}
                        >
                          Save
                        </Nav.Link>
                      </button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab6">
                    <div className="row">
                      <div className="custom-file col-md-12 col-lg-12">
                        <label className="">Agent Thumbnail Picture</label>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="propertyThumbnails"
                          onChange={async (e) => {
                            const base64 = await convertToBase64(
                              e.target.files[0]
                            );
                            setagentThumbnailUrl(base64);
                            setagentThumbnail(e.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        <label
                          className="custom-file-label cursor-pointer"
                          htmlFor="propertyThumbnails"
                        >
                          Choose File
                        </label>
                      </div>
                      {agentthumbnailUrl && agentthumbnailUrl ? (
                        <div className="row">
                          <img
                            className="col-lg-12 col-md-12"
                            src={agentthumbnailUrl}
                            alt="ThumbImage"
                            style={{ width: "30%" }}
                          />
                        </div>
                      ) : (
                        <img
                          className="col-lg-3 col-md-3"
                          src={previmage}
                          alt="ThumbImage"
                          style={{ width: "100px" }}
                        />
                      )}
                      <div className="col-md-6 cls form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="agentName"
                          value={agentName && agentName}
                          onChange={(e) => setAgentName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="agentLastName"
                          value={agentLastName}
                          onChange={(e) => setAgentLastName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Tel Number</label>
                        {toggleTelephone ? (
                          <PhoneInput
                            country={"th"}
                            value={countryCode + agentTel}
                            onChange={checkingFunction}
                            inputStyle={{ width: "100%" }}
                            placeholder="Tel Number"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tel Number"
                            value={agentTel}
                            onChange={(e) => setAgentTel(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setTelephone((prev) => !prev)}
                          className={` ${
                            toggleTelephone
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleTelephone
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Tel Number</label>
                        <div className="input-group">
                          <select
                            value={countryCode}
                            onChange={handleCountryChange}
                            style={{ border: "1px solid #e5e1dc", padding: "10px" }}
                          >
                            {countrycode.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Tel Number"
                            name="agentTel"
                            value={agentTel}
                            onChange={(e) => setAgentTel(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-6 form-group">
                        <label>Agent Service Area</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agent Service"
                          name="agentServices"
                          value={AgentService}
                          onChange={(e) => setAgentService(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Province</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Province"
                          name="agentProvince"
                          value={agentProvince}
                          onChange={(e) => setAgentProvince(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Country</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          name="agentCountry"
                          value={agentCountry}
                          onChange={(e) => setAgentCountry(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Line Id</label>
                        {toggleLineId ? (
                          <PhoneInput
                            country={"th"}
                            value={lineid}
                            onChange={(phone) => setlineid(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="Line Id"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Line Id"
                            value={lineid}
                            onChange={(e) => setlineid(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setToggleLineId((prev) => !prev)}
                          className={` ${
                            toggleLineId
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleLineId
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>WhatsApp</label>
                        {toggleAgentWhatsApp ? (
                          <PhoneInput
                            country={"th"}
                            value={agentwhatapp}
                            onChange={(phone) => setAgentWhatsApp(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="Agent WhatsApp"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Agent WhatsApp"
                            value={agentwhatapp}
                            onChange={(e) => setAgentWhatsApp(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() =>
                            setToggleAgentWhatsApp((prev) => !prev)
                          }
                          className={` ${
                            toggleAgentWhatsApp
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleAgentWhatsApp
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>WeChat ID</label>
                        {toggleWechatId ? (
                          <PhoneInput
                            country={"th"}
                            value={wechatId}
                            onChange={(phone) => setwechatId(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="WeChat ID"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="WeChat ID"
                            value={wechatId}
                            onChange={(e) => setwechatId(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setToggleWechatId((prev) => !prev)}
                          className={` ${
                            toggleWechatId
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleWechatId
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab7"
                            onClick={() => setTabKey("tab7")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab7">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="id"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Slug"
                          name="id"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsAndConditions"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termsAndConditions"
                        >
                          I Agree to the terms &amp; Conditions of Property
                          Submission
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab7"
                            onClick={() => setTabKey("tab8")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab8">
                    <div
                      className="form-group mt-4"
                      style={{ marginTop: marginTopValue }}
                    >
                      <label>Floor image</label>
                      <div
                        {...getFloorRootProps({
                          className: "dropzone cursor-pointer",
                        })}
                      >
                        <input {...getFloorInputProps()} multiple />
                        <div className="dropzone-msg dz-message needsclick">
                          <i className="fas fa-cloud-upload-alt" />
                          <h5 className="dropzone-msg-title">
                            Drop files here or click to upload.
                          </h5>
                          <span className="dropzone-msg-desc">
                            This is just a demo dropzone. Selected files are{" "}
                            <strong>not</strong> actually uploaded.
                          </span>
                        </div>
                      </div>
                      <aside
                        style={{ display: "flex" }}
                        className={thumbsContainer}
                      >
                        {/* {thumbs} */}
                      </aside>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      {Floorfiles?.length > 0 && Floorfiles[0]?.preview
                        ? Floorfiles.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                marginRight: "10px",
                                marginBottom: "20px",
                              }}
                            >
                              <img
                                key={index}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginBottom: "20px",
                                }}
                                src={image?.preview}
                                alt={image}
                              />
                            </div>
                          ))
                        : Floorfiles?.map((img, index) => (
                            <img
                              key={index}
                              style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "20px",
                                marginRight: "20px",
                              }}
                              src={img}
                              alt={img}
                            />
                          ))}
                    </div>

                    {disbtn ? (
                      <>
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        {/* <button
                      className="btn-custom text-dark"
                      disabled={true}
                      // name="submit"
                      // onClick={(e) => {
                      //   // console.log("awdawkd")
                      //   submitData(e);
                      // }}
                      >
                      Submit Listing
                    </button> */}
                      </>
                    ) : (
                      <>
                        <button
                          className="btn-custom"
                          name="submit"
                          onClick={(e) => {
                            // console.log("awdawkd")
                            submitData(e);
                          }}
                        >
                          Submit Listing
                        </button>
                      </>
                    )}
                    {user && user.isAdmin ? (
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => navigate("/")}
                      >
                        <span className="fa fa-reply"></span> Cancel
                      </button>
                    ) : (
                      <></>
                    )}
                    <div>
                      {error === undefined || false ? (
                        ""
                      ) : (
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              backgroundColor: "#FF3131",
                              color: "white",
                              padding: "10px 20px 10px 20px",
                              borderRadius: "5px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {fieldError ? " Please Fill Out All Fields" : ""}
                            </span>
                            <span>
                              {minFileError
                                ? " Please Select Images for Thumbnail or Gallery"
                                : ""}
                            </span>
                            <span>
                              {maxFileError
                                ? "You can select only 5 pictures"
                                : ""}
                            </span>
                            <button
                              style={{
                                border: "white 2px solid",
                                borderRadius: "25px",
                                width: "35px",
                                backgroundColor: "#FF3131",
                                color: "white",
                                fontSize: "15px",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setError(undefined);
                                setFieldError(undefined);
                                setMinFileError(undefined);
                                setMaxFileError(undefined);
                              }}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </form>
            </div>
          </Tab.Container>
          {/* Tab Content End */}
        </div>
      </Container>
    </div>
  );
}

export default Content; 
