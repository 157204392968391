import React, { useEffect, useState } from "react";
import testimonial from "../../data/testimonial.json";
import { processRatingStars } from "../../helper/helper";
import BASE_URL from "../baseUrl/baseUrl";
import AdminHeader from "../layouts/AdminHeader";
import AdminSider from "../layouts/AdminSider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import defaultimage from '../../assets/img/default.jpg'
const AdminTestimonials = (props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/pepleSay/get`)
      .then((res) => {
        setFlag(true);
        setData(res.data.data);
      });
  }, []);

  const deleteTestimonial = (id) => {
    axios
      .delete(`${BASE_URL}/admin/pepleSay/Delete/${id}`)
      .then((res) => {
        setData(res.data.result);

        const filterData = data.filter((item) => item._id !== id);
        setData(filterData);
        toast({
          title: "Success",
          description: "Currency deleted successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
      })
      .catch((err) => {
        setError(true);
        setErrorMsg(err);
      });
  };

  const addTestimonial = () => {
    navigate("/admin/testimonial/create");
  };

  const editTestimonial = (item) => {
    navigate(`/admin/testimonial/${item._id}`, { state: item });
  };

  // Pagination logic
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    // <div>
    //   <AdminHeader />
    //   <AdminSider url={props.url} />
    //   <div className="section section-padding">
    //   <div className="container">
    //     <div className="section-title-wrap section-header">
    //       <h5 className="custom-primary">Testimonials</h5>
    //       <h2 className="title">What Are People Saying</h2>
    //     </div>
    //     <div className="row">
    //       {/* Testimonail item start */}
    //       {Array.isArray(data) && data.map((item, i) => (
    //         <div key={i} className="col-lg-4 col-md-12">
    //           <div className="acr-testimonial">
    //             <div className="acr-testimonial-body">
    //               <h5>{item.title}</h5>
    //               <div className="acr-rating-wrapper">
    //                 <div className="acr-rating">
    //                   {processRatingStars(item?.rating)}
    //                 </div>
    //               </div>
    //               <p>{item.description}</p>
    //               {console.log(item.img)}
    //             </div>
    //             <div className="acr-testimonial-author">
    //               <img
    //                 src={item.img}
    //                 alt="testimonial"
    //               />
    //               <div className="acr-testimonial-author-inner">
    //                 <h6>{item.userName}</h6>
    //                 <span>{item.destination}</span>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //       {/* Testimonail item end */}
    //     </div>
    //   </div>
    // </div>
    // </div>
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Price Range</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px", overflowX: "scroll" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Testimonials</h2>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              width: "94%",
              margin: "1%",
            }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={() => addTestimonial()}
            >
              <span className="fa fa-plus"></span> Add Testimonials
            </button>
          </div>
          <div className="table-container-main">
            <div className="table-container-child">
              <table
                className="table-striped table-bordered text-center"
                style={{ width: "90%", margin: "1%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Ratings</th>
                    <th>Comment</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length === 0 && flag ? (
                    <tr className="text-center">
                      <td colSpan="5">No Data</td>
                    </tr>
                  ) : currentData.length === 0 && flag === false ? (
                    <tr className="text-center">
                      <td colSpan="5">Loading ...</td>
                    </tr>
                  ) : (
                    currentData?.map((res, key) => {
                      return (
                        <tr key={key}>
                          <td>{startIndex + key + 1}</td>
                          <td>{res.userName}</td>
                          <td>{res.rating}</td>
                          <td>{res.comment}</td>
                          <td style={{ display: "flex", justifyContent: "center" }}>
                            <img
                              src={`${res.img || defaultimage}`}
                              alt="NoImage"
                              style={{ width: "60px" }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-primary mr-2 w-[100px] mb-1 table-btn"
                              onClick={() => editTestimonial(res)}
                              style={{ borderRadius: "5px", marginRight: "10px" }}
                            >
                              <span className="fa fa-edit"></span>
                              Edit
                            </button>
                            <button
                              className="btn btn-danger mb-1 w-[100px]"
                              onClick={() => deleteTestimonial(res._id)}
                              style={{ borderRadius: "5px" }}
                            >
                              <span className="fa fa-trash"></span>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {currentData.length === 0 ? (
              null
            ) : (
              <nav aria-label="...">
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>

  );
};

export default AdminTestimonials;