import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";

const AdminHeaderEdit = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const loca = useLocation()
  const { state } = loca
  var subItemTitleId = ''

  const [id, setId] = useState("");
  const [titleName, setTitleName] = useState("")
  // console.log(state)
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [subLists, setSubList] = useState("");
  const [itemTitle, setItemTitle] = useState("");
  const [itemTitledes, setItemTitleDes] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [url, setUrl] = useState();
  const [icon, setIcon] = useState();


  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onCancel = () => {
    navigate("/admin/headercontent");
  };
  //   const postData = async () => {
  //     if (title === "") {
  //       toast({
  //         title: "Error",
  //         description: "Titlt feild is empty!!!",
  //         status: "error",
  //         duration: 2000,
  //         variant: "left-accent",
  //         position: "top-right",
  //         isClosable: true,
  //       });
  //       return false;
  //     }
  //     // if (code === "") {
  //     //   toast({
  //     //     title: "Error",
  //     //     description: "Code field is Empty!",
  //     //     status: "error",
  //     //     duration: 2000,
  //     //     variant: "left-accent",
  //     //     position: "top-right",
  //     //     isClosable: true,
  //     //   });
  //     //   return false;
  //     // }
  //     // if (symbol === "") {
  //     //   toast({
  //     //     title: "Error",
  //     //     description: "Symbol field is Empty!",
  //     //     status: "error",
  //     //     duration: 2000,
  //     //     variant: "left-accent",
  //     //     position: "top-right",
  //     //     isClosable: true,
  //     //   });
  //     //   return false;
  //     // }
  //     const formData = {
  //       title: title,

  //     };
  //     setFlag(true);
  //     await axios
  //       .post(
  //         `${BASE_URL}/NavList/api/mainlists`,
  //         formData
  //       )
  //       .then((res) => {
  //         setFlag(false);
  //         toast({
  //           title: "Success",
  //           description: "It has been saved successfully.",
  //           status: "success",
  //           duration: 2000,
  //           variant: "left-accent",
  //           position: "top-right",
  //           isClosable: true,
  //         });
  //         navigate("/admin/headercontent");
  //       })
  //       .catch((err) => {
  //         setFlag(false);
  //         setError(true);
  //         setErrorMsg(err);
  //       });
  //   };    
  //  var mainID = ""
  const handleEditTitle = () => {
    // Update the title using axios PUT request
    const requestData = {
      title: title,
    };

    axios
      .put(
        `${BASE_URL}/NavList/api/mainlists/${id}`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setFlag(false);
        toast({
          title: 'Success',
          description: 'Title has been updated successfully.',
          status: 'success',
          duration: 2000,
          variant: 'left-accent',
          position: 'top-right',
          isClosable: true,
        });
        navigate("/admin/headercontent");
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };


  const postData = () => {
    if (subLists === "") {
      toast({
        title: "Error",
        description: "Name field is empty!!!",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }

    const requestData = {
      title: subLists, // Assuming subLists is a string containing the title
    };

    axios.post(`${BASE_URL}/NavList/api/mainlists/${id}/sublists`, requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        subItemTitleId = res.data._id
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been updated successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        axios.post(
          `${BASE_URL}/NavList/mainlists/${id}/sublists/${subItemTitleId}/items`, formData)
          .then((res) => {
            setFlag(false);
            toast({
              title: "Success",
              description: "It has been updated successfully.",
              status: "success",
              duration: 2000,
              variant: "left-accent",
              position: "top-right",
              isClosable: true,
            });
            navigate("/admin/headercontent");
          })
          .catch((err) => {
            setFlag(false);
            setError(true);
            setErrorMsg(err);
          });
        // navigate("/admin/headercontent");
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
    // setData(id)
    const formData = new FormData();
    formData.append("title", itemTitle);
    formData.append("description", itemTitledes);
    formData.append("image", icon);

    // /mainlists/:mainListId/sublists/:subListId/items (post or get)


    // axios
    //     .post(
    //         `${BASE_URL}/NavList/api/mainlists/${id}/sublists`, formData)
    //     .then((res) => {
    //         setFlag(false);
    //         toast({
    //             title: "Success",
    //             description: "It has been updated successfully.",
    //             status: "success",
    //             duration: 2000,
    //             variant: "left-accent",
    //             position: "top-right",
    //             isClosable: true,
    //         });
    //         navigate("/admin/headercontent");
    //     })
    //     .catch((err) => {
    //         setFlag(false);
    //         setError(true);
    //         setErrorMsg(err);
    //     });

  };

  const onUpload = async (e) => {
    console.log(e.target.file)
    const base64 = await convertToBase64(e.target.files[0]);
    setUrl(base64);
    setIcon(e.target.files[0]);
  };

  useEffect(() => {
    setTitle(state.title)
    setId(state._id)
    // axios
    //   .get(`${BASE_URL}/NavList/api/mainlists`)
    //   .then((res) => {
    //     console.log(res.data, "get Data")
    //     setData(res.data);
    //   });
  }, [])
  // setFlag(true);   

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Helmet>
        <title>
          Buy Home For Less | Admin Currency Create
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Header Edit </h2>
          </div>
          <div
            className="acr-user-content"
            style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Title"
                  name="Title"
                />
              </div>
              {/* <div className="form-group">
            <label>Sub Title</label>
            <input
              type="text"
              value={subLists}
              onChange={(e) => setSubList(e.target.value)}
              className="form-control form-control-light"
              placeholder="Sub Title"
              name="Sub Title"
            />
          </div>
          <div className="form-group">
            <label>Sub Item Title </label>
            <input
              type="text"
              value={itemTitle}
              onChange={(e) => setItemTitle(e.target.value)}
              className="form-control form-control-light"
              placeholder="Sub Item Title"
              name="Sub Item Item"
            />
          </div>
          <div className="form-group">
            <label>Sub Item Title description </label>
            <input
              type="text"
              value={itemTitledes}
              onChange={(e) => setItemTitleDes(e.target.value)}
              className="form-control form-control-light"
              placeholder="Sub Item Description"
              name="Sub Item Description"
            />
          </div>
          <div className="form-group row">
            <label htmlFor="icon" className="cursor-pointer">
              Select Icon
            </label>
            <input
              onChange={onUpload}
              type="file"
              id="icon"
              name="icon"
              style={{ display: "none" }}
            />
            {url && url.slice(0, 7) === "uploads" ? (
              <img
                src={`${BASE_URL}/${url}`}
                alt="Icon"
                style={{ width: "100px" }}
              />
            ) : (
              <img src={url} alt="Icon" style={{ width: "100px" }} />
            )}
          </div> */}
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <button
                    type="Submit"
                    onClick={() => handleEditTitle()}
                    className="btn btn-primary"
                  >
                    <span className="fa fa-save"></span> Save
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => onCancel()}
                >
                  <span className="fa fa-reply"></span> Cancel
                </button>
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeaderEdit;
