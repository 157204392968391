import Banner from "./Banner";
import Listingwrapper from "./Listingwrapper";

const Content = ({setSeo}) => {
  return (
    <>
      <Banner />
      <div className="marginHomeContent">
        <Listingwrapper setSeo={setSeo} />
        
      </div>
    </>
  );
};

export default Content;
