import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl";

const AdminPromotion = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [name, setName] = useState();
  const [show, setShow] = useState();
  const [fileType, setFileType] = useState("");
  const [description, setDescription] = useState("");
  const [flag, setFlag] = useState(false);
  const [error, setError] = useState(false);
  const [noData, setData] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    axios
      .get(`${BASE_URL}/admin/popup`)
      .then((res) => {
         setName(res?.data?.data?.file)
         setFileType(res?.data?.data?.fileType);
      })
      .catch((err) => console.log("error", err));
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setShow(base64);
      setFileType(file.type);
    }
  };
  const postData = async () => {
    if (name === "") {
      toast({
        title: "Enter image or video",
        description: "image or video",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }    
    const formData = new FormData()
    formData.append("file", name)
    formData.append("check", true)
    formData.append("fileType",fileType)
    await axios
      .post(`${BASE_URL}/admin/popup`, formData)
      .then((res) => {
        setFlag(false);
        toast({
          title: "Success",
          description: "It has been saved successfully.",
          status: "success",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        return true;
      })
      .catch((err) => {
        setFlag(false);
        setError(true);
        setErrorMsg(err);
      });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const renderMedia = () => {
    if (fileType?.startsWith("image/")) {
      return <img style={{ margin: "auto" }} width="45%" height="50vh" src={show ? show : name} alt="Promotion Media" />;
    } else if (fileType?.startsWith("video/")) {
      return <video style={{ margin: "auto",width:"50vh" }} width="50vh" controls src={show ? show : name} />;
    }
    return null;
  };
  return (
    <div>
      <Helmet>
        <title>Buy Home For Less | Admin Seo</title>
        <meta name="description" content="#" />
      </Helmet>
      <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div style={{ display: "flex" }}>
        <AdminSider
          url={props.url}
          isOpen={sidebarOpen}
          onStateChange={(state) => setSidebarOpen(state.isOpen)}
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
        <div style={{ flex: 1, padding: "20px" }}>
          <div className="text-center" style={{ margin: "20px" }}>
            <h2>Promotion Manage</h2>
          </div>
          {/* <div style={{display:"contents"}}>
          <img style={{margin:"auto"}} className="text-center" 
          width="100px"
          height="100px"
          src={show?show:name} alt="Image not show"/>
          </div> */}
          <div style={{ display: "contents" }}>
            {renderMedia()}
          </div>
          <div
            className="acr-user-content"
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: sidebarOpen ? "20vh" : "0",
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              style={{
                width: "70%",
                padding: "2%",
              }}
            >
              <div className="form-group">
                <label>Add Promotion Image or Video</label>
                <input
                  type="file"
                  defaultValue={name}
                  onChange={(e) => {
                   handleFileChange(e)
                   setName(e.target.files[0])
                  }}
                  className="form-control form-control-light"
                  placeholder="Title"
                  name="username"
                />
              </div>
              {/* <div className="form-group">
                                <label>Slug</label>
                                <input
                                    type="text"
                                    defaultValue={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Slug"
                                    name="description"
                                />
                            </div> */}
              <div className="form-group text-right">
                {flag ? (
                  <button type="Submit" disabled className="btn btn-primary">
                    <span className="fa fa-save"></span> Saving...
                  </button>
                ) : (
                  <>
                    <button
                      type="Submit"
                      onClick={() => postData()}
                      className="btn btn-primary"
                    >
                      <span className="fa fa-save"></span> Save
                    </button>
                  </>
                )}
                {/* <button
              type="button"
              className="btn btn-default"
              onClick={() => onCancel()}
            >
              <span className="fa fa-reply"></span> Cancel
            </button> */}
              </div>
            </form>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPromotion;
