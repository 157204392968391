import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import AdminHeader from "./../layouts/AdminHeader";
import AdminSider from "./../layouts/AdminSider";
import convertToBase64 from "../../helper/convert";
import BASE_URL from "../baseUrl/baseUrl"
const Adminbanner = (props) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(false);
    const [noData, setData] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [id, setId] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/Banner/banner`);
                if (response.data.result.title) {
                    setName(response.data.result.title)
                    setDescription(response.data.result.description)
                    setData(true)
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData();
    }, []);

    const postData = async () => {
        console.log(name)
        if (name === "") {
            toast({
                title: "Error",
                description: "Title",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        if (description === "") {
            toast({
                title: "Error",
                description: "Enter description",
                status: "error",
                duration: 2000,
                variant: "left-accent",
                position: "top-right",
                isClosable: true,
            });
            return false;
        }
        const formdata = {
            title: name,
            description: description
        }
        setFlag(true);
        await axios
            .post(
                `${BASE_URL}/Banner/banner`,
                formdata
            )
            .then((res) => {
                setFlag(false);
                toast({
                    title: "Success",
                    description: "It has been saved successfully.",
                    status: "success",
                    duration: 2000,
                    variant: "left-accent",
                    position: "top-right",
                    isClosable: true,
                });
                return true;
            })
            .catch((err) => {
                setFlag(false);
                setError(true);
                setErrorMsg(err);
            });
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div>
            <Helmet>
                <title>
                    Buy Home For Less | Admin Banner
                </title>
                <meta name="description" content="#" />
            </Helmet>
            <AdminHeader collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
            <div style={{ display: "flex" }}>
                <AdminSider
                    url={props.url}
                    isOpen={sidebarOpen}
                    onStateChange={(state) => setSidebarOpen(state.isOpen)}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                />
                <div style={{ flex: 1, padding: "20px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                        <h2>Banner</h2>
                    </div>
                    <div
                        className="acr-user-content"
                        style={{ display: "flex", justifyContent: "center", marginLeft: sidebarOpen ? "20vh" : "0" }}
                    >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            style={{
                                width: "70%",
                                padding: "2%",
                            }}
                        >
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Title"
                                    name="username"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    defaultValue={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="form-control form-control-light"
                                    placeholder="Description"
                                    name="description"
                                />
                            </div>
                            <div className="form-group text-right">
                                {flag ? (
                                    <button type="Submit" disabled className="btn btn-primary">
                                        <span className="fa fa-save"></span> Saving...
                                    </button>
                                ) : (
                                    <><button
                                        type="Submit"
                                        onClick={() => postData()}
                                        className="btn btn-primary"
                                    >
                                        <span className="fa fa-save"></span> Save
                                    </button>


                                    </>
                                )}
                                {/* <button
              type="button"
              className="btn btn-default"
              onClick={() => onCancel()}
            >
              <span className="fa fa-reply"></span> Cancel
            </button> */}
                            </div>
                        </form>
                    </div >

                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {error ? (
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "70%",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    padding: "10px 20px 10px 20px",
                                    borderRadius: "5px",
                                    alignItems: "center",
                                }}
                            >
                                <span>{error ? `${errorMsg}` : ""}</span>
                                <div
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        border: "white 2px solid",
                                        borderRadius: "30px",
                                        width: "40px",
                                        backgroundColor: "#FF3131",
                                        height: "40px",
                                    }}
                                    onClick={() => {
                                        setError(false);
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "white",
                                            alignItems: "center",
                                            marginTop: "3px",
                                        }}
                                    >
                                        x
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Adminbanner;
