import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import footer from "../../assets/img/footerLogo.png"
import BASE_URL from "../baseUrl/baseUrl";
const Footercontent = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/Footer/get-footertext`);
        if (response.data.result) {
          setName(response.data?.result[0]?.title)
          setDescription(response.data?.result[0]?.content)
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 footer-widget">
            <div className="footer-logo">
              <h1 className="text-white text-[32px] 32px leading-[35px]	">Buy Home For Less</h1>
              
              <img
                src={footer}
                alt="acres"
              />
            </div>
            <p>
              {description}
            </p>
            <ul className="social-media">
              <li>
                {" "}
                <Link to="#">
                  {" "}
                  <i className="fab fa-facebook-f" />{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">
                  {" "}
                  <i className="fab fa-twitter" />{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">
                  {" "}
                  <i className="fab fa-pinterest-p" />{" "}
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">
                  {" "}
                  <i className="fab fa-linkedin-in" />{" "}
                </Link>{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-2 offset-md-1 col-md-4 col-sm-6 footer-widget">
            <h5 className="widget-title">Menu</h5>
            <ul>
              <li>
                {" "}
                <Link to="/">Find Home</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/submit-listing">Add Listing</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/agent">Become our Agent</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/about">About Us</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/chat">Complaint</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/blog">Blogs/News</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/sellproperty">Sell your property to us</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/contact">Contact us</Link>{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 footer-widget">
            <h5 className="widget-title">Legal</h5>
            <ul>
              <li>
                {" "}
                <Link to="/privacypolicy">Privacy Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/refundpolicy">Refund Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/cookiepolicy">Cookie Policy</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/termsandcond">Terms Condition</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">Find our agent worldwide</Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="#">Job</Link>{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-12 footer-widget">
            <h5 className="widget-title">Newsletter</h5>
            <p>
              Join our newsletter today, and get the best flats that we have to
              offer.
            </p>
            <form method="post">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Address"
                  name="newsletter-email"
                />
              </div>
              <button
                type="submit"
                className="btn-custom primary light btn-block"
                name="button"
              >
                Join Newsletter
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <p className="m-0">
                {name}
              </p>
            </div>
            <div className="col-lg-5">
              <ul>
                <li>
                  {" "}
                  <Link to="#">Find a Home</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#">Add Listing</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#">View Brokers</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footercontent;
