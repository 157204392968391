import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Locationtab from "./Locationtab";
import convertToBase64 from "../../../helper/convert";
import { Button, Select } from "antd";
import bathoom from "../../../bathoom.png";
import bedroom from "../../../bedroom.png";
import Swal from "sweetalert2";
import { Container, useToast } from "@chakra-ui/react";
import BASE_URL from "../../baseUrl/baseUrl";
import ConditionModal from "../../layouts/ConditionModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ConfirmModal from "./Modal";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "flex",
  flexDirection: "column",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Content() {
  const toast = useToast();
  const navigate = useNavigate();
  const [disbtn, setDisBtn] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [nearTypeList, setNearTypeList] = useState([]);
  const [tabKey, setTabKey] = useState("tab1");
  const [envormentalValue, setEnvormentalValue] = useState("");
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [metatag, setmetatag] = useState(null);
  const [keywrd, setkeywrd] = useState(null);
  const [descriptions, setdescriptions] = useState(null);
  const [firstCheckbox, setFirstCheckbox] = useState(false);
  const [secondCheckbox, setSecondCheckbox] = useState(false);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("th");
  const [MaintainanceFee, setMaintainanceFee] = useState("");
  const [Furnishedproperties, setFurnishedproperties] = useState([]);
  const [Furnished, setFurnished] = useState("");

  useEffect(() => {
    axios.get(`${BASE_URL}/admin/get-categories`).then((res) => {
      console.log(res.data.result);
      setTypeList(res.data.result);
    });
    axios.get(`${BASE_URL}/admin/get-currencies`).then((res) => {
      setCurrencyList(res.data.result);
    });
    // axios
    //   .get(`${BASE_URL}/admin/get-features`)
    //   .then((res) => {
    //     setFeatureList(res.data.result);
    //   });
    axios.get(`${BASE_URL}/admin/get-nearbytypes`).then((res) => {
      setNearTypeList(res.data.result);
    });
  }, []);

  // Error
  const [error, setError] = useState(undefined);
  const [fieldError, setFieldError] = useState(undefined);
  const [minFileError, setMinFileError] = useState(undefined);
  const [maxFileError, setMaxFileError] = useState(undefined);
  const [listingtype, setlistingtype] = useState();
  //  Basic Information
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState(0);
  const [Monthlymortage, setMonthlymortage] = useState();
  const [period, setPeriod] = useState("");
  const [space, setSpace] = useState(0);
  const [land, setLand] = useState(0);
  const [video, setVideo] = useState("");
  const [rental, setRental] = useState(false);
  const [Jacuzziprop, setJacuzziprop] = useState("");
  const [Gallery, setGallery] = useState([]);
  const [agentthumbnail, setagentThumbnail] = useState();
  const [agentthumbnailUrl, setagentThumbnailUrl] = useState();
  const [Floorimage, setFloorimage] = useState([]);
  const [Aircondition, setAircondition] = useState(" ");
  const [solarPower, setsolarPower] = useState("");
  const [seaview, setseaview] = useState("");
  const [hotWater, sethotWater] = useState("");
  const [Electricity, setElectricity] = useState("");
  const [paidwater, setpaidwater] = useState("");
  const [penthouse, setpenthouse] = useState("");
  const [Elevator, setElevator] = useState("");
  // console.log(rental);
  //  Gallery
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const [files, setFiles] = useState([]);
  const [Floorfiles, setFloorFiles] = useState([]);
  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: { "image/*": [] },
  //   onDrop: (acceptedFiles) => {
  //     setFiles(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  // });
  const {
    getRootProps: getGalleryRootProps,
    getInputProps: getGalleryInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      // Check if there are any accepted files
      if (acceptedFiles && acceptedFiles.length > 0) {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        // Check if any file matches the thumbnail name
        const thumbnailName = thumbnail?.name;
        if (
          thumbnailName &&
          newFiles.some((file) => file.name === thumbnailName)
        ) {
          toast({
            title: "Error Occured",
            description: "Cannot upload thumbnail image",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "bottom-left",
          });
          return; // Stop execution
        }
        setGallery([...Gallery, ...newFiles]);
        // Replace the files state with the new array of files
        setFiles(newFiles);
      }
    },
  });
  const { getRootProps: getFloorRootProps, getInputProps: getFloorInputProps } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop: (acceptedFiles) => {
        // Check if there are any accepted files
        if (acceptedFiles && acceptedFiles.length > 0) {
          const newFiles = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );

          // Check if any file matches the thumbnail name
          const thumbnailName = thumbnail?.name;
          if (
            thumbnailName &&
            newFiles.some((file) => file.name === thumbnailName)
          ) {
            toast({
              title: "Error Occured",
              description: "Cannot upload thumbnail image",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "bottom-left",
            });
            return; // Stop execution
          }
          setFloorimage([...Floorimage, ...newFiles]);
          setFloorFiles(newFiles);
        }
      },
    });
  // console.log(Gallery);
  const handleDeleteImage = (name) => {
    const updatedFiles = Gallery.filter((file) => file.name !== name);
    setFiles(updatedFiles);
    setGallery(updatedFiles);
  };
  const handleFloorDeleteImage = (name) => {
    const updatedFiles = Floorimage.filter((file) => file.name !== name);
    setFloorFiles(updatedFiles);
    setFloorimage(updatedFiles);
  };

  const thumbs = Gallery.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="ThumbImage"
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <button
        style={{ backgroundColor: "#ADD8E6" }}
        onClick={() => handleDeleteImage(file.name)}
      >
        Delete
      </button>
    </div>
  ));
  const Floorthumbs = Floorimage.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt="ThumbImage"
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <button
        style={{ backgroundColor: "#ADD8E6" }}
        onClick={() => handleFloorDeleteImage(file.name)}
      >
        Delete
      </button>
    </div>
  ));
  // console.log(files);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  });

  //  Location
  const [location, setLocation] = useState({
    address: "",
    country: "",
    city: "",
    provice: "",
    zipcode: "abc",
    lat: "",
    long: "",
    tumbol: "",
    embedCode: "",
  });
  const locationData = (data) => {
    console.log(data);
    setLocation(data);
  };
  const statusList1 = [
    { value: "Featured", label: "Featured" },
    { value: "For Sale", label: "For Sale" },
    { value: "Leased", label: "Leased" },
    { value: "New Addition", label: "New Addition" },
    { value: "Sold", label: "Sold" },
    // { value: "Rental", label: "Rental" },
    { value: "Reduced", label: "Reduced" },
    { value: "Special Offer", label: "Special Offer" },
  ];
  const statusList2 = [
    { value: "Featured", label: "Featured" },
    { value: "Leased", label: "Leased" },
    { value: "New Addition", label: "New Addition" },
    { value: "Sold", label: "Sold" },
    { value: "For Rent", label: "For Rent" },
    { value: "Reduced", label: "Reduced" },
    { value: "Special Offer", label: "Special Offer" },
  ];
  const FurnishListing = [
    { value: "TV", label: "TV" },
    { value: "Bed", label: "Bed" },
    { value: "Air condition", label: "Air condition" },
    { value: "refrigerator", label: "refrigerator" },
    { value: "Stove", label: "Stove" },
  ];
  //  Feature
  // const [features, setFeatures] = useState([]);
  // const featuresData = (id) => {
  //   if (features.indexOf(id) !== -1) {
  //     features.splice(features.indexOf(id), 1);
  //   } else {
  //     setFeatures([...features, id]);
  //   }
  // };

  //  Details
  const [id, setId] = useState(0);
  const [tenniscourt, settenniscourt] = useState("");
  const [beds, setBeds] = useState(0);
  const [baths, setBaths] = useState(0);
  const [condition, setCondition] = useState("");
  const [built, setBuilt] = useState("");
  const [neighbor, setNeighbor] = useState("");
  const [living, setLiving] = useState("");
  const [dining, setDining] = useState("");
  const [story, setStory] = useState(0);
  const [landSize, setLandSize] = useState("");
  const [parking, setParking] = useState("");
  const [lotsize, setLotsize] = useState("");
  const [view, setView] = useState("");
  const [nears, setNears] = useState({ rows: [] });
  const [nearId, setNearId] = useState([]);

  const [meetingroom, setMeetingroom] = useState("");
  const [gameroom, setGameroom] = useState("");
  const [maid, setMaid] = useState("");
  const [library, setLibrary] = useState("");
  const [jacuzzi, setJacuzzi] = useState("");
  const [sauna, setSauna] = useState("");
  const [swimmingpool, setSwimmingpool] = useState("");
  const [guest, setGuest] = useState("");
  const [gym, setGyms] = useState("");
  const [laundryroom, setLaundryroom] = useState("");
  const [roomNo, setroomNo] = useState("");
  const [RoomFloor, setRoomFloor] = useState("");

  const addRow = () => {
    const newRow = {
      neartype: nearTypeList[0]._id,
      name: "",
      distance: "",
      isEdit: false,
      key: "",
    };

    const selectRow = [...nears.rows];

    setNears({
      rows: [...selectRow, newRow],
    });
  };

  const enableEdit = (e, idx) => {
    e.preventDefault();
    const multy = [...nears.rows];
    if (e.target.innerHTML === "Edit") {
      e.target.innerHTML = "Save";
      multy[idx].isEdit = false;
    } else {
      e.target.innerHTML = "Edit";
      multy[idx].isEdit = true;
      console.log(multy[idx]);
      axios
        .post(`${BASE_URL}/admin/near/create`, multy[idx])

        .then((res) => {
          multy[idx].key = res.data.result._id;
          setNearId([...nearId, res.data.result._id]);
        })
        .catch((err) => console.log(err));
    }

    setNears({
      rows: [...multy],
    });
  };

  const removeRow = (e, idx) => {
    e.preventDefault();
    const selectRow = [...nears.rows];

    if (selectRow[idx].isEdit) {
      axios
        .delete(`${BASE_URL}/admin/delete/near/${selectRow[idx].key}`)
        .then((res) => {
          nearId.splice(nearId.indexOf(res.data.result), 1);
        })
        .catch((err) => console.log(err));
    }
    selectRow.splice(idx, 1);
    setNears({
      rows: [...selectRow],
    });
  };

  const [bbqarea, setBBQArea] = useState("");
  const [partyhall, setPartyHall] = useState("");
  const [communityHall, setCommunityHall] = useState("");
  const [swimmingpoolguest, setSwimmingpoolguest] = useState("");
  const [guestgym, setGymguest] = useState("");
  const [guestParking, setGuestParking] = useState("");
  const [minimart, setMinimart] = useState("");
  const [libraryguest, setLibraryguest] = useState("");
  const [park, setPark] = useState("");
  const [playGround, setPlayGround] = useState("");
  const [rentalPrice, setRentalPrice] = useState();
  const [agentName, setAgentName] = useState();
  const [agentLastName, setAgentLastName] = useState();
  const [agentTel, setAgentTel] = useState();
  const [agentwhatapp, setAgentWhatsApp] = useState();
  const [agentProvince, setAgentProvince] = useState();
  const [agentCountry, setAgentCountry] = useState();
  const [videofile, setVideofile] = useState("");
  const [lineid, setlineid] = useState();
  const [wechatId, setwechatId] = useState();
  const [modal, setModal] = useState("");
  const [emptyfield, setemptyField] = useState([]);
  const [toggleLineId, setToggleLineId] = useState(true);
  const [toggleWechatId, setToggleWechatId] = useState(true);
  const [toggleAgentWhatsApp, setToggleAgentWhatsApp] = useState(true);
  const [toggleTelephone, setTelephone] = useState(true);
  const [currencymonthly, setCurrencymonthly] = useState("");
  const [currencyMin, setCurrencyMin] = useState("");
  const [currencyMax, setcurrencyMax] = useState("");
  const [currencyMaintainance, setcurrencyMaintainance] = useState("");
  const [AgentService, setAgentService] = useState("");
  //  Validation
  const validate = () => {
    if (!user) {
      Swal.fire({
        title: "You need to login first!",
        // text: 'You need to login first.',
        icon: "warning",
        confirmButtonText: "Okay",
      });
      return false;
    }

    const ErrFields = [
      { name: description, text: "Description" },
      { name: name, text: "Name" },
      { name: status, text: "Status" },
      { name: type, text: "Type" },
      // { name: price, text: "Sale Price Name" },
      // { name: Monthlymortage, text: "Monthly Mortage" },
      { name: MaintainanceFee, text: "Maintainance Fee" },
      // { name: Furnished, text: "Furnished" },
      // { name: "Furnishedproperties", text: "FurnishedProperty" },
      { name: minPrice, text: "Min Price" },
      { name: maxPrice, text: "Max Price" },
      // { name: space, text: "Building Area" },
      // { name: land, text: "Land Area" },
      // { name: rentalPrice, text: "Rental Price" },
      // { name: "video", text: "Video url or VideoFile" },
      // { name: videofile, text: "Video file" },
      { name: thumbnail, text: "Thumbnail", tab: "tab2" },
      { name: files, text: "Gallery", tab: "tab2" },
      // { name: location.address, text: "Address", tab: "tab3" },
      // { name: location.country, text: "Country", tab: "tab3" },
      // { name: location.tumbol, text: "Tumbol", tab: "tab3" },
      // { name: location.city, text: "City", tab: "tab3" },
      // { name: location?.provice, text: "Provice", tab: "tab3" },
      // { name: location.zipcode, text: "Zip Code", tab: "tab3" },
      { name: location.embedCode, text: "Embed Link", tab: "tab3" },
      // { name: bbqarea, text: "BBQ Area", tab: "tab4" },
      // { name: partyhall, text: "Party Hall", tab: "tab4" },
      // { name: communityHall, text: "Community Hall", tab: "tab4" },
      // { name: swimmingpoolguest, text: "Swimming Pool", tab: "tab4" },
      // { name: guestgym, text: "Gym", tab: "tab4" },
      // { name: guestParking, text: "Guest Parking", tab: "tab4" },
      // { name: minimart, text: "Mini Mart", tab: "tab4" },
      // { name: libraryguest, text: "Library", tab: "tab4" },
      // { name: park, text: "Park", tab: "tab4" },
      // { name: playGround, text: "Play Ground", tab: "tab4" },
      // { name: Aircondition, text: "Fans", tab: "tab4" },
      // { name: solarPower, text: "solarPower", tab: "tab4" },
      // { name: seaview, text: "seaview", tab: "tab4" },
      // { name: hotWater, text: "hotWater", tab: "tab4" },
      // { name: oceanFront, text: "oceanFront", tab: "tab4" },
      // { name: outdoorBath, text: "outdoorBath", tab: "tab4" },
      // { name: id, text: "ID", tab: "tab5" },
      // { name: beds, text: "Beds", tab: "tab5" },
      // { name: baths, text: "Baths", tab: "tab5" },
      // { name: condition, text: "Condition", tab: "tab5" },
      // { name: built, text: "Year Built", tab: "tab5" },
      // { name: neighbor, text: "Neighborhood", tab: "tab5" },
      // { name: living, text: "Living Room", tab: "tab5" },
      // { name: dining, text: "Dining Room", tab: "tab5" },
      // { name: laundryroom, text: "Laundry Room", tab: "tab5" },
      // { name: meetingroom, text: "Meeting room", tab: "tab5" },
      // { name: gameroom, text: "Game Room", tab: "tab5" },
      // { name: jacuzzi, text: "Jacuzzi", tab: "tab5" },
      // { name: sauna, text: "Sauna", tab: "tab5" },
      // { name: maid, text: "Maid Room", tab: "tab5" },
      // { name: guest, text: "Guest room", tab: "tab5" },
      // { name: story, text: "Building Floor", tab: "tab5" },
      // { name: guestParking, text: "Parking", tab: "tab5" },
      // { name: lotsize, text: "Room size", tab: "tab5" },
      // { name: view, text: "View", tab: "tab5" },
      // { name: firstCheckbox, text: "Please fill the check box", tab: "tab5" },

      { name: agentthumbnail, text: "Agent Picture", tab: "tab6" },
      { name: agentName, text: "Agent Name", tab: "tab6" },
      { name: agentLastName, text: "Agent Last Name", tab: "tab6" },
      { name: agentTel, text: "Agent Tel", tab: "tab6" },
      { name: AgentService, text: "Agent Service", tab: "tab6" },
      { name: agentProvince, text: "Agent Province", tab: "tab6" },
      { name: agentCountry, text: "Country Province", tab: "tab6" },
      { name: lineid, text: "Line Id", tab: "tab6" },
      { name: wechatId, text: "We Chat Id", tab: "tab6" },
      { name: agentwhatapp, text: "Agent Whatapp", tab: "tab6" },
      { name: secondCheckbox, text: "Please fill the check box", tab: "tab7" },
      // { name: Floorfiles, text: "Insert Floor image", tab: "tab8" },
    ];

    if (
      listingtype === "Rent" &&
      Furnished == "yes" &&
      Furnishedproperties.length === 0
    ) {
      toast({
        title: "Error",
        description: "Please select Furnished properties for Rent listings",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false;
    }
    for (let i = 0; i < ErrFields.length; i++) {
      if (ErrFields[i].name === status && status === "") {
        toast({
          title: "Error",
          description: "Insert Property " + ErrFields[i].text,
          status: "error",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        setTabKey("tab1");
        return false; // Break the loop and return false
      }
      // if (ErrFields[i].text == "Video url or VideoFile") {
      //   if (!video && !videofile?.name) {
      //     toast({
      //       title: "Error",
      //       description: "Insert Property " + ErrFields[i].text,
      //       status: "error",
      //       duration: 2000,
      //       variant: "left-accent",
      //       position: "top-right",
      //       isClosable: true,
      //     });
      //     setTabKey("tab1");
      //     return false;
      //   }
      // }
      if (ErrFields[i].text == "Gallery") {
        if (Gallery.length === 0) {
          toast({
            title: "Error",
            description: "Insert Property Gallery",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab2");
          return false;
        }
        if (Gallery.length > 15) {
          toast({
            title: "Error",
            description: "You can upload only 15 pictures",
            status: "warning",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab2");
          return false;
        }
      }
      if (ErrFields[i].text == "Agent Picture") {
        if (files.length === 0) {
          toast({
            title: "Error",
            description: "Insert Property Agent Picture",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab6");
          return false;
        }
      }
      if (!ErrFields[i].name) {
        if (
          listingtype !== "Rent" &&
          (ErrFields[i].name === Furnished ||
            ErrFields[i].name === Furnishedproperties)
        ) {
          continue;
        }
        if (
          listingtype === "Rent" &&
          Furnishedproperties.length === 0 &&
          Furnished !== "no"
        ) {
          toast({
            title: "Error",
            description: "Please select Furnished properties for Rent listings",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab1");
          return false;
        }
        toast({
          title: "Error",
          description: "Insert Property " + ErrFields[i].text,
          status: "error",
          duration: 2000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
        setTabKey(ErrFields[i]?.tab ? ErrFields[i]?.tab : "tab1");
        return false; // Break the loop and return false
      }
      if (ErrFields[i].text === "Insert Floor image") {
        if (Floorfiles.length === 0) {
          toast({
            title: "Error",
            description: "Insert Floor images",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setTabKey("tab8");
          return false;
        }
      }
    }

    if (listingtype === "Rent" && !rentalPrice) {
      toast({
        title: "Error",
        description: "Insert Rental Price",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }
    if (listingtype !== "Rent" && (!price || !Monthlymortage)) {
      toast({
        title: "Error",
        description: "Insert Property Price or Monthly mortage",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }
    if (!listingtype) {
      toast({
        title: "Error",
        description: "Insert Listing type",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      setTabKey("tab1");
      return false; // Break the loop and return false
    }

    return true;
  };

  const submitlistingdata = () => {
    console.log("hello");
    // setModal(true);
    let emptyArray = [
      { name: "land", value: land, text: "Land" },
      { name: "space", value: space, text: "Space" },
      // { name: "video", value: video, text: "Video" },
      // { name: "videofile", value: videofile, text: "Videofile" },
      { name: "location.address", value: location.address, text: "Address" },
      { name: "location.long", value: location.long, text: "Longitude" },
      { name: "location.lat", value: location.lat, text: "Latitude" },
      { name: "Floorfiles", value: Floorfiles, text: "Floor Files" },
      { name: "bbqarea", value: bbqarea, tab: "tab4", text: "Bbqarea" },
      { name: "partyhall", value: partyhall, tab: "tab4", text: "Partyhall" },
      {
        name: "communityHall",
        value: communityHall,
        tab: "tab4",
        text: "Community Hall",
      },
      {
        name: "swimmingpools",
        value: swimmingpoolguest,
        tab: "tab4",
        text: "Swimming pools",
      },
      { name: "guestgym", value: guestgym, tab: "tab4", text: "Guestgym" },
      {
        name: "guestParking",
        value: guestParking,
        tab: "tab4",
        text: "GuestParking",
      },
      { name: "minimart", value: minimart, tab: "tab4", text: "Mini mart" },
      {
        name: "libraryguest",
        value: libraryguest,
        tab: "tab4",
        text: "Library guest",
      },
      { name: "park", value: playGround, tab: "tab4", text: "Park" },
      {
        name: "tenniscourt",
        value: tenniscourt,
        tab: "tab4",
        text: "Tennis court",
      },
      { name: "Fans", value: Aircondition, tab: "tab4", text: "Fans" },
      {
        name: "solarPower",
        value: solarPower,
        tab: "tab4",
        text: "SolarPower",
      },
      { name: "seaview", value: seaview, tab: "tab4", text: "Seaview" },
      // { name: "hotWater", value: paidwater, tab: "tab4", text: "HotWater" },
      {
        name: "Elevator",
        value: Elevator,
        tab: "tab4",
        text: "Elevator",
      },
      {
        name: "PentHouse",
        value: penthouse,
        tab: "tab4",
        text: "Pent House",
      },
      {
        name: "playGround",
        value: playGround,
        tab: "tab4",
        text: "PlayGround",
      },
      { name: "id", value: id, tab: "tab5", text: "Id" },
      { name: "beds", value: beds, tab: "tab5", text: "Beds" },
      { name: "baths", value: baths, tab: "tab5", text: "Baths" },
      { name: "story", value: story, tab: "tab5", text: "Story" },
      { name: "lotsize", value: lotsize, tab: "tab5", text: "PlayGround" },
      // { name: "built", value: lotsize, tab: "tab5",text: "PlayGround"  },
      {
        name: "neighborhood",
        value: neighbor,
        tab: "tab5",
        text: "Neighborhood",
      },
      { name: "living", value: living, tab: "tab5", text: "Living" },
      { name: "dining", value: dining, tab: "tab5", text: "Dining" },
      {
        name: "laundryroom",
        value: laundryroom,
        tab: "tab5",
        text: "Laundryroom",
      },
      {
        name: "meetingroom",
        value: meetingroom,
        tab: "tab5",
        text: "Meetingroom",
      },
      { name: "gameroom", value: gameroom, tab: "tab5", text: "Gameroom" },
      { name: "jacuzzi", value: jacuzzi, tab: "tab5", text: "Jacuzzi" },
      { name: "sauna", value: sauna, tab: "tab5", text: "Sauna" },
      { name: "maid", value: maid, tab: "tab5", text: "Maid" },
      { name: "guest", value: guest, tab: "tab5", text: "Guest" },
      {
        name: "propertybuilt",
        value: built,
        tab: "tab5",
        text: "Propertybuilt",
      },
      { name: "view", value: view, tab: "tab5", text: "View" },
      { name: "condition", value: condition, tab: "tab5", text: "Condition" },
      { name: "title", value: title, text: "Title", tab: "tab7" },
      { name: "slug", value: slug, text: "Slug", tab: "tab7" },
      { name: "metatag", value: metatag, text: "Meta Tag", tab: "tab7" },
      { name: "keywrd", value: keywrd, text: "Key Work", tab: "tab7" },
      {
        name: "descriptions",
        value: descriptions,
        text: "Descriptions",
        tab: "tab7",
      },
      // { name: firstCheckbox, text: "Please fill the check box", tab: "tab5" },
    ];
    if (!videofile && !video) {
      emptyArray.push(
        { name: "video", value: video, text: "Video" },
        { name: "videofile", value: videofile, text: "Videofile" }
      );
    }
    if(listingtype == "Rent"){
      emptyArray.push(
       { name: "paidwater", value: paidwater, tab: "tab4", text: "Paid Water" },
      {
        name: "Electricity",
        value: Electricity,
        tab: "tab4",
        text: "Electricity",
      },
      );
    }
    let emptyFields = emptyArray
      ?.filter(
        (item) =>
          item.value === "" ||
          (Array.isArray(item.value) && item.value.length === 0) ||
          item.value === null ||
          item.value === undefined
      )
      .map((item) => item.text);

    if (emptyFields?.length === 0) {
      setModal(false); // Close the modal
      submitData(); // Call submitData function to proceed
    } else {
      setemptyField(emptyFields); // Set empty fields in the state to highlight them or show messages
      setModal(true); // Keep the modal open
    }
  };
  //  Submit
  const submitData = (e) => {
    if (e) {
      e.preventDefault(); // Check if the event is present before preventing default behavior
    }
    setemptyField([]);
    setModal(false);
    if (!validate()) {
      return;
    }
    if (user === null) {
      toast({
        title: "Error",
        description: "You need to login first.",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    } else {
      // console.error("Status is not an array:", status);

      // if (!Array.isArray(status)) {
      //   console.error("Status is not an array:", status);
      //   return;
      // }
      const copy = countryCode.split(" ");
      setCountryCode(copy[0]);
      setAgentTel(copy[1]);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("status", status);
      formData.append("type", type ? type : typeList[0].name);
      formData.append("currency", currency ? currency : currencyList[0].symbol);
      formData.append("price", price);
      formData.append("period", period ? period : "Monthly");
      formData.append("rentalPrice", rentalPrice ? rentalPrice : "");
      formData.append("space", space);
      formData.append("land", land);
      formData.append("video", video ? video : "");
      formData.append("videofile", videofile ? videofile : "");
      formData.append("listingType", listingtype);
      formData.append("maintainanceFee", MaintainanceFee);
      formData.append("roomfloor", RoomFloor || '');
      formData.append("roomNo", roomNo || '');
      formData.append(
        "maintainanceFeecurrency",
        currencyMaintainance || currencyList[0].symbol
      );
      formData.append("isFurnished", Furnished);
      formData.append("FurnishedProperties", Furnishedproperties);
      thumbnail.forEach((file, inx) => formData.append("thumbnail", file));
      files.map((file, inx) => formData.append("picture", file));

      formData.append("latitude", location.lat);
      formData.append("longitude", location.long);
      formData.append("embedCode", location.embedCode);
      formData.append("tumbol", location.tumbol);
      formData.append("address", location.address);
      formData.append("country", location.country);
      formData.append("city", location.city);
      formData.append("provice", location?.provice);
      formData.append("zipcode", location.zipcode);
      formData.append("title", title);
      formData.append("slug", slug);
      formData.append("metaTag", metatag);
      formData.append("keyWords", keywrd);
      formData.append("seodes", descriptions);

      // formData.append("features", features);

      formData.append("id", id);
      formData.append("beds", beds);
      formData.append("bathrooms", baths);
      formData.append("condition", condition);
      formData.append("built", built);
      formData.append("neighbor", neighbor);
      formData.append("living", living);
      formData.append("dining", dining);
      formData.append("story", story);
      formData.append("parking", parking);
      formData.append("lotsize", lotsize);
      formData.append("view", view);
      // formData.append("landSize", landSize);
      formData.append("near", nearId);
      // formData.append('gyms', gym);
      // formData.append('library', library);
      formData.append("laundryRoom", laundryroom);
      formData.append("gameRoom", gameroom);
      formData.append("sauna", sauna);
      formData.append("maidRoom", maid);
      formData.append("guestRoom", guest);
      formData.append("meetingRoom", meetingroom);
      formData.append("jacuzzi", jacuzzi);
      // formData.append('swimmingPool', swimmingpool);
      formData.append("envormentValue", envormentalValue);
      formData.append("category", type ? type : typeList[0].name);
      formData.append("Fans", Aircondition);
      formData.append("tenniscourt", tenniscourt);
      formData.append("solarPower", solarPower);
      formData.append("seaview", seaview);
      formData.append("hotWater", paidwater);
      formData.append("oceanFront", Electricity);
      formData.append("outdoorBath", Elevator);
      formData.append("penthouse", penthouse);
      // formData.append("buy");

      formData.append("BBQArea", bbqarea);
      formData.append("PartyHall", partyhall);
      formData.append("CommunityHall", communityHall);
      formData.append("SwimmingPool", swimmingpoolguest);
      formData.append("Gym", guestgym);
      formData.append("GuestParking", guestParking);
      formData.append("MiniMart", minimart);
      formData.append("Library", libraryguest);
      formData.append("Park", park);
      formData.append("playGround", playGround);
      formData.append("mortage", Monthlymortage);
      formData.append(
        "mortagecurrency",
        currencymonthly ? currencymonthly : currencyList[0].symbol
      );

      formData.append("agentthumbnail", agentthumbnail);
      formData.append("agentLastName", agentLastName);
      formData.append("agentName", agentName);
      formData.append("agentTel", agentTel);
      formData.append("agentServices", AgentService);
      formData.append("countryCode", countryCode);
      formData.append("agentwhatapp", agentwhatapp);
      formData.append("agentProvince", agentProvince);
      formData.append("agentCountry", agentCountry);
      formData.append("Line_Id", lineid);
      formData.append("WeChatID", wechatId);
      formData.append("minRange", minPrice);
      formData.append("maxRange", maxPrice);
      formData.append(
        "maxRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      formData.append(
        "minRangecurrency",
        currencyMin ? currencyMin : currencyList[0].symbol
      );
      Floorfiles.map((file, inx) => formData.append("Floorimage", file));

      formData.append("authorId", user._id);
      setDisBtn(() => true);
      console.log(formData);

      axios
        .post(`${BASE_URL}/submitlisting/submit`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setDisBtn(() => false);
          toast({
            title: "Success",
            description: "Property created successfully",
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
          setemptyField([]);
          // const Msg = res.data.Msg;
          // navigate("/admin/properties");
          if (listingtype === "Sell") {
            navigate("/admin/sellproperty");
          } else {
            navigate("/admin/rentproperty");
          }
        })
        .catch((err) => {
          setDisBtn(() => false);
          setemptyField([]);
          // const Msg = err.response.data.Msg;
          // if (Msg === "Please Fill Out All Feilds") {
          // setError(true);
          // setFieldError(true);
          // setMinFileError(false);
          // } else if (Msg === "Please Fill Thumbnail Picture") {
          // setError(true);
          // setFieldError(false);
          // setMinFileError(true);
          // } else if (Msg === "You can upload only 5 pictures") {
          // setError(true);
          // setFieldError(false);
          // setMinFileError(false);
          // setMaxFileError(true);
          // } else {\
          // }
        });
      setemptyField([]);
    }
  };

  // const handleChange = (value) => {
  //   setStatus(value);
  //   const hasRental = value.includes("Rental");
  //   setRental(hasRental);
  // };

  const handleChange = (values) => {
    setFurnishedproperties(values);
  };

  const handlefile = (event) => {
    const file = event.target.files[0];
    // console.log(file);
    if (file) {
      setVideofile(file);
    }
  };

  const handleCountryChange = (e) => {
    setCountryCode(e.target.value);
  };

  const countrycode = [
    "+966", // Saudi Arabia
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+66", // Thailand
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleThumbnailChange = async (e) => {
    const files = Array.from(e.target.files);
    const base64Promises = files.map((file) => convertToBase64(file));
    const base64Array = await Promise.all(base64Promises);

    setThumbnailUrl(base64Array);
    setThumbnail(files);
  };

  const handleDeleteThumbnail = (index) => {
    const updatedThumbnails = thumbnail.filter((_, i) => i !== index);
    const updatedThumbnailUrl = thumbnailUrl.filter((_, i) => i !== index);
    setThumbnail(updatedThumbnails);
    setThumbnailUrl(updatedThumbnailUrl);
  };
  const handleButtonClick = (buttonType) => {
    setlistingtype(buttonType);
  };
  const checkingFunction = (buttonType) => {
    const countryCodes = [
      "93",
      "355",
      "213",
      "376",
      "244",
      "1-268",
      "54",
      "374",
      "61",
      "43",
      "994",
      "1-242",
      "973",
      "880",
      "1-246",
      "375",
      "32",
      "501",
      "229",
      "975",
      "591",
      "387",
      "267",
      "55",
      "673",
      "359",
      "226",
      "257",
      "238",
      "855",
      "237",
      "1",
      "236",
      "235",
      "56",
      "86",
      "57",
      "269",
      "243",
      "242",
      "506",
      "385",
      "53",
      "357",
      "420",
      "45",
      "253",
      "1-767",
      "1-809",
      "593",
      "20",
      "503",
      "240",
      "291",
      "372",
      "268",
      "251",
      "679",
      "358",
      "33",
      "241",
      "220",
      "995",
      "49",
      "233",
      "30",
      "1-473",
      "502",
      "224",
      "245",
      "592",
      "509",
      "504",
      "36",
      "354",
      "91",
      "62",
      "98",
      "964",
      "353",
      "972",
      "39",
      "1-876",
      "81",
      "962",
      "7",
      "254",
      "686",
      "850",
      "82",
      "383",
      "965",
      "996",
      "856",
      "371",
      "961",
      "266",
      "231",
      "218",
      "423",
      "370",
      "352",
      "261",
      "265",
      "60",
      "960",
      "223",
      "356",
      "692",
      "222",
      "230",
      "52",
      "691",
      "373",
      "377",
      "976",
      "382",
      "212",
      "258",
      "95",
      "264",
      "674",
      "977",
      "31",
      "64",
      "505",
      "227",
      "234",
      "389",
      "47",
      "968",
      "92",
      "680",
      "507",
      "675",
      "595",
      "51",
      "63",
      "48",
      "351",
      "974",
      "40",
      "7",
      "250",
      "1-869",
      "1-758",
      "1-784",
      "685",
      "378",
      "239",
      "966",
      "221",
      "381",
      "248",
      "232",
      "65",
      "421",
      "386",
      "677",
      "252",
      "27",
      "211",
      "34",
      "94",
      "249",
      "597",
      "46",
      "41",
      "963",
      "886",
      "992",
      "255",
      "66",
      "670",
      "228",
      "676",
      "1-868",
      "216",
      "90",
      "993",
      "688",
      "256",
      "380",
      "971",
      "44",
      "1",
      "598",
      "998",
      "678",
      "39",
      "58",
      "84",
      "967",
      "260",
      "263",
    ];

    for (const code of countryCodes) {
      if (buttonType.startsWith(code)) {
        const number = buttonType.slice(code.length).trim(); // Extract number after the country code
        console.log(code);
        console.log(number);

        setCountryCode(code);
        setAgentTel(number);
        return;
      }
    }

    console.error("No matching country code found");
    setCountryCode("");
    setAgentTel("");
  };

  const marginTopValue =
    thumbnailUrl && thumbnailUrl.length > 0 ? "25vh" : "0vh";

  return (
    <div className="section">
      <Container maxW="80%">
        <div className="row">
          <Tab.Container defaultActiveKey={tabKey} activeKey={tabKey}>
            {/* Tabs Start */}
            <div className="col-md-4">
              <Nav variant="tabs" className="nav nav-tabs tab-cards">
                <Nav.Item>
                  <Nav.Link eventKey="tab1" onClick={() => setTabKey("tab1")}>
                    <span>01</span> Basic Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2" onClick={() => setTabKey("tab2")}>
                    <span>02</span> Gallery
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3" onClick={() => setTabKey("tab3")}>
                    <span>03</span> Location
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab4" onClick={() => setTabKey("tab4")}>
                    <span>04</span> Property Amenity
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab5" onClick={() => setTabKey("tab5")}>
                    <span>05</span> Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab6" onClick={() => setTabKey("tab6")}>
                    <span>06</span> Agent Dashboard
                  </Nav.Link>
                </Nav.Item>
                {user && user.isAdmin == true && (
                  <Nav.Item>
                    <Nav.Link eventKey="tab7" onClick={() => setTabKey("tab7")}>
                      <span>07</span> Seo
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="tab8" onClick={() => setTabKey("tab8")}>
                    <span>08</span> Floor Plan
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {/* Tabs End */}
            {/* Tab Content Start */}
            <div className="col-md-8">
              <form onSubmit={(e) => e.preventDefault()}>
                <Tab.Content className="m-0">
                  <Tab.Pane eventKey="tab1">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <div className="d-flex justify-center">
                          <Button
                            // type="primary"
                            className={`mr-2 px-5 py-3 ${
                              listingtype === "Sell" ? "selected" : ""
                            }`}
                            onClick={() => handleButtonClick("Sell")}
                          >
                            Sell
                          </Button>
                          <Button
                            // type="primary"

                            className={`px-5 py-3 ${
                              listingtype === "Rent" ? "selected" : ""
                            }`}
                            onClick={() => handleButtonClick("Rent")}
                          >
                            Rent
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-12 form-group">
                        <label>Property Description</label>
                        <textarea
                          name="content"
                          rows={4}
                          className="form-control"
                          placeholder="Property Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Property Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Name"
                          required
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Property Status</label>
                        {listingtype === "Rent" ? (
                          <>
                            <select
                              className="form-control"
                              name="status"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              {statusList2.map((res, key) => (
                                <option key={key} value={res?.value}>
                                  {res?.label}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : (
                          <>
                            <select
                              className="form-control"
                              name="status"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">Select Property Status</option>
                              {statusList1.map((res, key) => (
                                <option key={key} value={res?.value}>
                                  {res?.label}
                                </option>
                              ))}
                            </select>
                          </>
                        )}

                        {/* <Select
                          style={{ width: "100%" }}
                          placeholder="PropertyStatus"
                          value={status}
                          onChange={handleChange}
                        >
                          {statusList.map((status) => (
                            <select.Option key={status.value} value={status.value}>
                              {t(status.label)}
                            </select.Option>
                          ))}
                        </Select> */}
                      </div>
                      <div className="col-md-6">
                        <label>Property Type</label>
                        <select
                          className="form-control"
                          name="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">Select Property Type</option>
                          {console.log(typeList)}
                          {typeList.map((res, key) => {
                            return (
                              <option key={key} value={res.name}>
                                {res.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="col-md-6">
                        <label>Listing Type</label>
                        <Select
                          mode="tags"
                          style={{
                            width: "100%",
                          }}
                          placeholder="Listing Type"
                          onChange={(value) => setlistingtype(value)}
                        >
                          <Select.Option value="Buy">Buy</Select.Option>
                          <Select.Option value="Rent">Rent</Select.Option>
                          <Select.Option value="Sell">Sell</Select.Option>
                        </Select>
                      </div> */}
                      {listingtype == "Rent" ? (
                        <>
                          <div className="col-md-6">
                            <label>Rental Period</label>
                            <select
                              className="form-control"
                              name="period"
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                            >
                              <option value="Monthly">Monthly</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Daily">Daily</option>
                            </select>
                          </div>
                          <div className="col-md-6 form-group">
                            <label>Rental Price</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <select
                                  className="form-control"
                                  name="type"
                                  value={currency}
                                  onChange={(e) => setCurrency(e.target.value)}
                                >
                                  {currencyList.map((res, key) => (
                                    <option key={key} value={res.symbol}>
                                      {res.symbol}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <input
                                className="form-control"
                                name="rentalPrice"
                                value={rentalPrice}
                                onChange={(e) => setRentalPrice(e.target.value)}
                                type="number"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {listingtype !== "Rent" ? (
                        <div className="col-md-6 form-group">
                          <label>Sale Price</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select
                                className="form-control"
                                name="type"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                              >
                                {currencyList.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Property Price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {listingtype !== "Rent" ? (
                        <div className="col-md-6 form-group">
                          <label>Monthly Mortage</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select
                                className="form-control"
                                name="type"
                                value={currencymonthly}
                                onChange={(e) =>
                                  setCurrencymonthly(e.target.value)
                                }
                              >
                                {currencyList.map((res, key) => (
                                  <option key={key} value={res.symbol}>
                                    {res.symbol}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Monthly Mortage"
                              value={Monthlymortage}
                              onChange={(e) =>
                                setMonthlymortage(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-6 form-group">
                        <label>Maintainance Fee</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMaintainance}
                              onChange={(e) =>
                                setcurrencyMaintainance(e.target.value)
                              }
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={MaintainanceFee}
                            onChange={(e) => setMaintainanceFee(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>
                      {listingtype === "Rent" ? (
                        <div className="col-md-6 form-group">
                          <label>Furnished</label>
                          <select
                            className="form-control"
                            name="community hall"
                            value={Furnished}
                            onChange={(e) => setFurnished(e.target.value)}
                          >
                            <option value="">Select the value</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                      {listingtype === "Rent" && Furnished == "yes" ? (
                        <div className="col-md-6 form-group">
                          <label>Furnished Property</label>
                          <Select
                            mode="multiple" // Enable multiple selection
                            style={{ width: "100%" }}
                            placeholder="Select Furnished Amenities"
                            value={Furnishedproperties.value}
                            onChange={handleChange}
                          >
                            {FurnishListing?.map((status) => (
                              <Select.Option
                                key={status.value}
                                value={status.value}
                              >
                                {status.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      ) : (
                        ""
                      )}
                      {(type == "Condo" || type == "Apartment") && (
                        <div
                          className={`col-md-6 form-group ${
                            listingtype === "Sell" ? "mt-0" : "mt-0"
                          }`}
                        >
                          <label>Room#</label>
                          <div className="input-group">
                            <input
                              className="form-control"
                              name="period"
                              type="number"
                              value={roomNo}
                              onChange={(e) => setroomNo(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                      {
                          (type == "Condo" || type == "Apartment") && (
                            <div className="col-md-6 form-group ">
                            <label>Room Floor</label>
                            <input
                              type="number"
                              className="form-control"
                              name="buildstory"
                              value={RoomFloor}
                              onChange={(e) => setRoomFloor(e.target.value)}
                            />
                          </div>
                          )}
                      <div className="col-md-6 form-group">
                        <label>Min-Price</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMin}
                              onChange={(e) => setCurrencyMin(e.target.value)}
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Max-Price</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <select
                              className="form-control"
                              name="type"
                              value={currencyMin}
                              onChange={(e) => setCurrencyMin(e.target.value)}
                            >
                              {currencyList.map((res, key) => (
                                <option key={key} value={res.symbol}>
                                  {res.symbol}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="number"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                            className="form-control form-control-light"
                            placeholder="price"
                            name="name"
                          />
                        </div>
                      </div>

                      {(type == "Condo" ||
                        type == "Apartment" ||
                        type == "Flats") && (
                        <div
                          className={`col-md-6 form-group ${
                            listingtype === "Sell" ? "mt-0" : "mt-0"
                          }`}
                        >
                          <label>ROOM AREA SQM (Optional)</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Room Area"
                            name="space"
                            // value={space}
                            onChange={(e) => setSpace(e.target.value)}
                          />
                        </div>
                      )}
                      <div className="col-md-6 form-group">
                        <label>Land Area (SQM) (Optional)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Land Area (Sqm)"
                          name="space"
                          value={land}
                          onChange={(e) => setLand(e.target.value)}
                        />
                        <div
                          style={{
                            display: "flex",
                            width: "60px",
                            marginTop: "10px",
                          }}
                        >
                          <img src={bathoom} />
                          <img src={bedroom} />
                        </div>
                      </div>
                      <div className="col-md-12 form-group">
                        <label>Property Video (Optional)</label>
                        <input
                          type="text"
                          className="form-control mb-1"
                          placeholder="Property Video URL"
                          name="video"
                          value={video}
                          onChange={(e) => setVideo(e.target.value)}
                        />
                        <input
                          type="file"
                          className="form-control"
                          placeholder="PropertyVideo"
                          name="videofile"
                          accept="video/*"
                          onChange={handlefile}
                        />
                      </div>
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab2")}
                            >
                              Save
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    <div className="form-group row">
                      <label>Property Thumbnail</label>
                      <div className="custom-file col-md-4 col-lg-3">
                        {/* <input
                          type="file"
                          className="custom-file-input"
                          id="propertyThumbnail"
                          onChange={async (e) => {
                            const base64 = await convertToBase64(
                              e.target.files[0]
                            );
                            setThumbnailUrl(base64);
                            setThumbnail(e.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        <label
                          className="custom-file-label cursor-pointer"
                          htmlFor="propertyThumbnail"
                        >
                          Choose File
                        </label>
                        
                        {thumbnailUrl ? (
                          <img
                          className="col-lg-3 col-md-3"
                          src={thumbnailUrl}
                          alt="ThumbImage"
                          style={{ width: "100px" }}
                        />
                      ) : (
                        ""
                      )} */}
                        <input
                          type="file"
                          className="custom-file-input d-none"
                          id="propertyThumbnail"
                          multiple
                          onChange={handleThumbnailChange}
                        />
                        <label
                          className="custom-file-label cursor-pointer"
                          htmlFor="propertyThumbnail"
                        >
                          Choose File
                        </label>
                        <div className="img-preview">
                          {thumbnailUrl &&
                            thumbnailUrl?.map((url, index) => (
                              <div key={index}>
                                <img
                                  src={url}
                                  alt={`Thumbnail ${index + 1}`}
                                  className="col-lg-3 col-md-3 mt-2"
                                  style={{ width: "100px", aspectRatio: "3/3" }}
                                />
                                <button
                                  style={{
                                    backgroundColor: "#ADD8E6",
                                    height: "25%",
                                    width: "100%",
                                  }}
                                  onClick={() => handleDeleteThumbnail(index)}
                                >
                                  Delete
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="form-group"
                      style={{ marginTop: marginTopValue }}
                    >
                      <label>Property Gallery</label>
                      <div
                        {...getGalleryRootProps({
                          className: "dropzone cursor-pointer",
                        })}
                      >
                        <input {...getGalleryInputProps(-5)} multiple />
                        <div className="dropzone-msg dz-message needsclick">
                          <i className="fas fa-cloud-upload-alt" />
                          <h5 className="dropzone-msg-title">
                            Drop files here or click to upload.
                          </h5>
                          <span className="dropzone-msg-desc">
                            This is just a demo dropzone. Selected files are{" "}
                            <strong>not</strong> actually uploaded.
                          </span>
                        </div>
                      </div>
                      <aside
                        style={{ display: "flex", flexWrap: "wrap" }}
                        // className={`${}`}
                      >
                        {thumbs}
                      </aside>
                      <span className="acr-form-notice">
                        *You can upload up to 15 images for your listing
                      </span>
                      <span className="acr-form-notice">
                        *Listing images should be atleast 620x480 in dimensions
                      </span>
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab4"
                            onClick={() => setTabKey("tab3")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab3">
                    <Locationtab locationData={locationData} />
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab4"
                            onClick={() => setTabKey("tab4")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    <div className="row">
                      {/* {featureList.map((res, key) => (
                        <div key={key} className="col-lg-4 col-md-6 col-sm-6">
                          <label className="acr-listing-feature">
                            <input
                              type="checkbox"
                              name={"feature" + res._id + ""}
                              onClick={() => featuresData(res._id)}
                            />
                            <i className="acr-feature-check fas fa-check" />
                            <i style={{ textAlign: "-webkit-center" }}>
                              <img
                                className="acr-listing-feature-icon"
                                src={`${res.icon}`}
                                alt="ThumbImage"
                                style={{ marginBottom: "20px", width: '200px', height: '200px' }}
                              />
                            </i>
                            {res.name}
                          </label>
                        </div>
                      ))} */}

                      <div className="col-md-6 form-group">
                        <label>BBQ Area</label>
                        <select
                          className="form-control"
                          name="bbqarea"
                          value={bbqarea}
                          onChange={(e) => setBBQArea(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Party Hall</label>
                        <select
                          className="form-control"
                          name="partyhall"
                          value={partyhall}
                          onChange={(e) => setPartyHall(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Community Hall</label>
                        <select
                          className="form-control"
                          name="community hall"
                          value={communityHall}
                          onChange={(e) => setCommunityHall(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Swimming Pool</label>
                        <select
                          className="form-control"
                          name="swimmingpools"
                          value={swimmingpoolguest}
                          onChange={(e) => setSwimmingpoolguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Gym</label>
                        <select
                          className="form-control"
                          name="guestgym"
                          value={guestgym}
                          onChange={(e) => setGymguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Guest Parking</label>
                        <select
                          className="form-control"
                          name="guestParking"
                          value={guestParking}
                          onChange={(e) => setGuestParking(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Mini Mart</label>
                        <select
                          className="form-control"
                          name="minimart"
                          value={minimart}
                          onChange={(e) => setMinimart(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Library</label>
                        <select
                          className="form-control"
                          name="libraryguest"
                          value={libraryguest}
                          onChange={(e) => setLibraryguest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>

                      <div className="col-md-6 form-group">
                        <label>Park</label>
                        <select
                          className="form-control"
                          name="park"
                          value={park}
                          onChange={(e) => setPark(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Play Ground</label>
                        <select
                          className="form-control"
                          name="playGround"
                          value={playGround}
                          onChange={(e) => setPlayGround(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Tennis Court</label>
                        <select
                          className="form-control"
                          name="tenniscourt"
                          value={tenniscourt}
                          onChange={(e) => settenniscourt(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"AirCondition"}</label>
                        <select
                          className="form-control"
                          name="Fans"
                          value={Aircondition}
                          onChange={(e) => setAircondition(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"SolarPower"}</label>
                        <select
                          className="form-control"
                          name="solarPower"
                          value={solarPower}
                          onChange={(e) => setsolarPower(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>{"View"}</label>
                        <select
                          className="form-control"
                          name="seaview"
                          value={seaview}
                          onChange={(e) => setseaview(e.target.value)}
                        >
                          <option value="">Select value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                          <label>Elevator</label>
                          <select
                            className="form-control"
                            name="Elevator"
                            value={Elevator}
                            onChange={(e) => setElevator(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                       {
                        listingtype == "Rent" && (
                          <div className="col-md-6 form-group">
                          <label>Paid Electricity</label>
                          <select
                            className="form-control"
                            name="Electricity"
                            value={Electricity}
                            onChange={(e) => setElectricity(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                        )
                       }
                        {
                          listingtype == "Rent"&& (
                            <div className="col-md-6 form-group">
                          <label>Paid water</label>
                          <select
                            className="form-control"
                            name="paidwater"
                            value={paidwater}
                            onChange={(e) => setpaidwater(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                          )
                        }
                        <div className="col-md-6 form-group">
                          <label>Pent House</label>
                          <select
                            className="form-control"
                            name="PentHouse"
                            value={penthouse}
                            onChange={(e) => setpenthouse(e.target.value)}
                          >
                            <option value="">Select value</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab5")}
                            >
                              Save
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab5">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Property ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property ID"
                          name="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Beds</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of Beds"
                          name="beds"
                          value={beds}
                          onChange={(e) => setBeds(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Bathrooms</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of Bathrooms"
                          name="baths"
                          value={baths}
                          onChange={(e) => setBaths(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Condition</label>
                        <select
                          className="form-control"
                          onChange={(e) => setCondition(e.target.value)}
                          value={condition}
                        >
                          <option value="">Select Condition</option>
                          <option value="Used">Used</option>
                          <option value="New">New</option>
                          <option value="Satisfactory">Satisfactory</option>
                          <option value="Fixer upper">Fixer upper</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Year Built</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Year Built"
                          name="propertybuilt"
                          value={built}
                          onChange={(e) => setBuilt(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Neighborhood</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Property Neighborhood"
                          name="neighborhood"
                          value={neighbor}
                          onChange={(e) => setNeighbor(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Living Room</label>
                        {/* <select
                          className="form-control"
                          name="livingRoom"
                          value={living}
                          onChange={(e) => setLiving(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select> */}
                        <input
                          type="number"
                          className="form-control"
                          name="livingRoom"
                          value={living}
                          onChange={(e) => setLiving(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Dining Room</label>
                        {/* <select
                          className="form-control"
                          name="diningRoom"
                          value={dining}
                          onChange={(e) => setDining(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select> */}
                        <input
                          type="number"
                          className="form-control"
                          name="diningRoom"
                          value={dining}
                          onChange={(e) => setDining(e.target.value)}
                        ></input>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Gyms</label>
                        <select
                          className="form-control"
                          name="gyms"
                          value={gym}
                          onChange={(e) => setGyms(e.target.value)}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div> */}
                      <div className="col-md-6 form-group">
                        <label>Laundry Room</label>
                        <select
                          className="form-control"
                          name="laundryroom"
                          value={laundryroom}
                          onChange={(e) => setLaundryroom(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Meeting room</label>
                        <select
                          className="form-control"
                          name="meetingroom"
                          value={meetingroom}
                          onChange={(e) => setMeetingroom(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Game Room</label>
                        <select
                          className="form-control"
                          name="gameroom"
                          value={gameroom}
                          onChange={(e) => setGameroom(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Jacuzzi</label>
                        <select
                          className="form-control"
                          name="jacuzzi"
                          value={jacuzzi}
                          onChange={(e) => setJacuzzi(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Sauna</label>
                        <select
                          className="form-control"
                          name="sauna"
                          value={sauna}
                          onChange={(e) => setSauna(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Maid Room</label>
                        <select
                          className="form-control"
                          name="maidroom"
                          value={maid}
                          onChange={(e) => setMaid(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Guest room</label>
                        <select
                          className="form-control"
                          name="guestroom"
                          value={guest}
                          onChange={(e) => setGuest(e.target.value)}
                        >
                          <option value="">Select the value</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Building Floor</label>
                        <input
                          type="number"
                          className="form-control"
                          name="buildstory"
                          value={story}
                          onChange={(e) => setStory(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Parking</label>
                        <select
                          onChange={(e) => setParking(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Parking</option>
                          <option value="1-2 Car Garage">1-2 Car Garage</option>
                          <option value="3 Car Garage">3 Car Garage</option>
                          <option value="4 Car Garage">4 Car Garage</option>
                          <option value="5 Car Garage">5 Car Garage</option>
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>
                          {listingtype === "Sell" &&
                          (type === "Residential" ||
                            type === "Villa" ||
                            type === "Resort")
                            ? "BuildingSize (SQM)"
                            : "Roomsize(SQM)"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={
                            listingtype === "Sell" &&
                            (type === "Residential" ||
                              type === "Villa" ||
                              type === "Resort")
                              ? "BuildingSize (SQM)"
                              : "Roomsize(SQM)"
                          }
                          name="Room Size"
                          value={lotsize}
                          onChange={(e) => setLotsize(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>View</label>
                        <select
                          onChange={(e) => setView(e.target.value)}
                          className="form-control"
                        >
                          <option>Select View</option>
                          <option value="Beach">Beach</option>
                          <option value="Mountain">Mountain</option>
                          <option value="Lake">Lake</option>
                          <option value="Cities Life">Cities Life</option>
                          <option value="Rural hills">Rural hills</option>
                        </select>
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>{"Land Size/ SQM"}</label>
                        <input
                          type="number"
                          placeholder={"Land Size/ SQM"}
                          className="form-control"
                          name="landSize"
                          value={landSize}
                          onChange={(e) => setLandSize(e.target.value)}
                        />
                      </div> */}
                    </div>
                    <div className="row">
                      <label>Enter the Nearby </label>
                      <table
                        className="table table-bordered text-center"
                        style={{ margin: "0px 20px" }}
                      >
                        <thead>
                          <tr className="roof">
                            <th className="col-3"> Select Type </th>
                            <th className="col-4"> Name</th>
                            <th className="col-2"> Distance (km)</th>
                            <th className="col-3">
                              <button
                                className="btn btn-success"
                                onClick={() => addRow()}
                              >
                                + Add Row
                              </button>{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {nears.rows.map((item, idx) => (
                            <>
                              <tr key={idx}>
                                <td>
                                  <select
                                    className="form-control"
                                    value={item.type}
                                    name="type"
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].neartype = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  >
                                    {nearTypeList.map((res, key) => (
                                      <option key={key} value={res._id}>
                                        {res.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={item.name}
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].name = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="distance"
                                    value={item.distance}
                                    disabled={nears.rows[idx].isEdit}
                                    onChange={(e) => {
                                      const multy = [...nears.rows];
                                      multy[idx].distance = e.target.value;
                                      setNears({
                                        rows: [...multy],
                                      });
                                    }}
                                  />
                                </td>
                                <td
                                  className="td-valid"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="btn btn-primary mb-1 mr-1"
                                    onClick={(e) => {
                                      enableEdit(e, idx);
                                    }}
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-danger mb-1"
                                    onClick={(e) => removeRow(e, idx)}
                                    style={{ borderRadius: "5px" }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                              <td>
                                {nears.rows[0]?.neartype ==
                                  "64db14b79e7ad116420e7a18" && (
                                  <select
                                    defaultValue={envormentalValue}
                                    onChange={(e) =>
                                      setEnvormentalValue(e.target.value)
                                    }
                                    className="form-control"
                                  >
                                    {nearTypeList[5].Sublist.map((res, key) => (
                                      <option key={key} value={res}>
                                        {res}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </td>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsAndConditions"
                          checked={firstCheckbox}
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termsAndConditions"
                        >
                          I Agree to the terms &amp; Conditions of Property
                          Submission
                        </label>
                      </div>
                    </div>
                    <div>
                      {error === undefined || false ? (
                        ""
                      ) : (
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                              backgroundColor: "#FF3131",
                              color: "white",
                              padding: "10px 20px 10px 20px",
                              borderRadius: "5px",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {fieldError ? " Please Fill Out All Fields" : ""}
                            </span>
                            <span>
                              {minFileError
                                ? " Please Select Images for Thumbnail or Gallery"
                                : ""}
                            </span>
                            <span>
                              {maxFileError
                                ? "You can select only 5 pictures"
                                : ""}
                            </span>
                            <button
                              style={{
                                border: "white 2px solid",
                                borderRadius: "25px",
                                width: "35px",
                                backgroundColor: "#FF3131",
                                color: "white",
                                fontSize: "15px",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                setError(undefined);
                                setFieldError(undefined);
                                setMinFileError(undefined);
                                setMaxFileError(undefined);
                              }}
                            >
                              x
                            </button>
                          </div>
                        </div>
                      )}
                      {user && !user?.isAdmin ? (
                        disbtn ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn-custom"
                            name="submit"
                            onClick={(e) => {
                              // console.log("awdawkd")
                              submitData(e);
                            }}
                          >
                            Submit Listing
                          </button>
                        )
                      ) : null}
                      {!user ? (
                        disbtn ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <button
                            className="btn-custom"
                            name="submit"
                            onClick={(e) => {
                              // console.log("awdawkd")
                              submitData(e);
                            }}
                          >
                            Submit Listing
                          </button>
                        )
                      ) : null}
                      <div className="col-md-12 form-group">
                        <div className="w-25">
                          <button className="btn btn-primary px-2 py-2 w-100">
                            <Nav.Link
                              eventKey="tab4"
                              onClick={() => setTabKey("tab6")}
                            >
                              Save
                            </Nav.Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab6">
                    <div className="row">
                      <div className="custom-file col-md-12 col-lg-12">
                        <label className="">Agent Thumbnail Picture</label>
                        <input
                          type="file"
                          className="custom-file-input"
                          id="propertyThumbnails"
                          onChange={async (e) => {
                            const base64 = await convertToBase64(
                              e.target.files[0]
                            );
                            setagentThumbnailUrl(base64);
                            setagentThumbnail(e.target.files[0]);
                          }}
                          style={{ display: "none" }}
                        />
                        <label
                          className="custom-file-label cursor-pointer"
                          htmlFor="propertyThumbnails"
                        >
                          Choose File
                        </label>
                      </div>
                      {agentthumbnailUrl ? (
                        <div className="row">
                          <img
                            className="col-lg-12 col-md-12"
                            src={agentthumbnailUrl}
                            alt="ThumbImage"
                            style={{ width: "30%" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-6 cls form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="agentName"
                          // value={agentName}
                          onChange={(e) => setAgentName(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="agentLastName"
                          // value={agentLastName}
                          onChange={(e) => setAgentLastName(e.target.value)}
                        />
                      </div>
                      {/* <div className="col-md-6 form-group">
                        <label>Tel Number</label>
                        <PhoneInput
                          country={"th"} // Set default country code, e.g., "cn" for China
                          value={countryCode}
                          name="Line Id"
                          onChange={checkingFunction}
                          inputStyle={{ width: "100%" }} // Set the input width to match other form fields
                          placeholder="Tel Number " // Placeholder text for the input field
                        />
                      </div> */}
                      <div className="col-md-6 form-group">
                        <label>Tel Number</label>
                        {toggleTelephone ? (
                          <PhoneInput
                            country={"th"}
                            value={agentTel}
                            onChange={(phone) => setAgentTel(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="WeChat ID"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tel Number"
                            value={agentTel}
                            onChange={(e) => setAgentTel(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setTelephone((prev) => !prev)}
                          className={` ${
                            toggleTelephone
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleTelephone
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Province</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Province"
                          name="agentProvince"
                          // value={agentProvince}
                          onChange={(e) => setAgentProvince(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Agent Service Area</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agent Service"
                          name="agentServices"
                          // value={agentCountry}
                          onChange={(e) => setAgentService(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Country</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Country"
                          name="agentCountry"
                          // value={agentCountry}
                          onChange={(e) => setAgentCountry(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Line Id</label>
                        {toggleLineId ? (
                          <PhoneInput
                            country={"th"}
                            value={lineid}
                            onChange={(phone) => setlineid(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="Line Id"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Line Id"
                            value={lineid}
                            onChange={(e) => setlineid(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setToggleLineId((prev) => !prev)}
                          className={` ${
                            toggleLineId
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleLineId
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>

                      {/* WeChat ID Input */}
                      <div className="col-md-6 form-group">
                        <label>WeChat ID</label>
                        {toggleWechatId ? (
                          <PhoneInput
                            country={"th"}
                            value={wechatId}
                            onChange={(phone) => setwechatId(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="WeChat ID"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="WeChat ID"
                            value={wechatId}
                            onChange={(e) => setwechatId(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() => setToggleWechatId((prev) => !prev)}
                          className={` ${
                            toggleWechatId
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleWechatId
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>

                      {/* Agent WhatsApp Input */}
                      <div className="col-md-6 form-group">
                        <label>WhatsApp</label>
                        {toggleAgentWhatsApp ? (
                          <PhoneInput
                            country={"th"}
                            value={agentwhatapp}
                            onChange={(phone) => setAgentWhatsApp(phone)}
                            inputStyle={{ width: "100%" }}
                            placeholder="Agent WhatsApp"
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Agent WhatsApp"
                            value={agentwhatapp}
                            onChange={(e) => setAgentWhatsApp(e.target.value)}
                          />
                        )}
                        <button
                          onClick={() =>
                            setToggleAgentWhatsApp((prev) => !prev)
                          }
                          className={` ${
                            toggleAgentWhatsApp
                              ? "border-2 bg-[#519fff] border-white rounded-lg p-2 text-white"
                              : "bg-[white] text-black rounded-lg border-2 border-color-black mt-1 p-2"
                          }`}
                        >
                          {toggleAgentWhatsApp
                            ? "Switch to Text Input"
                            : "Switch to Phone Input"}
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab7"
                            onClick={() => setTabKey("tab7")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab7">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="id"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Slug"
                          name="id"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-12 form-group">
                        <label>Meta Tag</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Meta Tag"
                          name="id"
                          value={metatag}
                          onChange={(e) => setmetatag(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Keyword</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Keyword"
                          name="id"
                          value={keywrd}
                          onChange={(e) => setkeywrd(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="id"
                          value={descriptions}
                          onChange={(e) => setdescriptions(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{ marginTop: "30px" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsAndConditions"
                          checked={secondCheckbox}
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termsAndConditions"
                        >
                          I Agree to the terms &amp; Conditions of Property
                          Submission
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="w-25">
                        <button className="btn btn-primary px-2 py-2 w-100">
                          <Nav.Link
                            eventKey="tab7"
                            onClick={() => setTabKey("tab8")}
                          >
                            Save
                          </Nav.Link>
                        </button>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab8">
                    <div className="row">
                      <div className=" col-md-12">
                        <div className="form-group">
                          <label>Floor Images (Optional)</label>
                          <div
                            {...getFloorRootProps({
                              className: "dropzone cursor-pointer",
                            })}
                          >
                            <input {...getFloorInputProps(-5)} multiple />
                            <div className="dropzone-msg dz-message needsclick">
                              <i className="fas fa-cloud-upload-alt" />
                              <h5 className="dropzone-msg-title">
                                Drop files here or click to upload.
                              </h5>
                              <span className="dropzone-msg-desc">
                                This is just a demo dropzone. Selected files are{" "}
                                <strong>not</strong> actually uploaded.
                              </span>
                            </div>
                          </div>
                          <aside
                            style={{ display: "flex", flexWrap: "wrap" }}
                            // className={thumbsContainer}
                          >
                            {Floorthumbs}
                          </aside>
                          {/* <span className="acr-form-notice">
                            *You can upload up to 15 images for your listing
                          </span>
                          <span className="acr-form-notice">
                            *Listing images should be atleast 620x480 in
                            dimensions
                          </span> */}
                        </div>
                      </div>
                      <div className="flex">
                        {user && user.isAdmin == true && disbtn ? (
                          <>
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn-custom"
                              name="submit"
                              onClick={() => {
                                // console.log("awdawkd")
                                submitlistingdata();
                              }}
                            >
                              Submit Listing
                            </button>
                          </>
                        )}

                        {user && user.isAdmin ? (
                          <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => navigate("/")}
                          >
                            <span className="fa fa-reply"></span> Cancel
                          </button>
                        ) : (
                          <></>
                        )}
                        <div>
                          {error === undefined || false ? (
                            ""
                          ) : (
                            <div
                              style={{
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "80%",
                                  backgroundColor: "#FF3131",
                                  color: "white",
                                  padding: "10px 20px 10px 20px",
                                  borderRadius: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <span>
                                  {fieldError
                                    ? " Please Fill Out All Fields"
                                    : ""}
                                </span>
                                <span>
                                  {minFileError
                                    ? " Please Select Images for Thumbnail or Gallery"
                                    : ""}
                                </span>
                                <span>
                                  {maxFileError
                                    ? "You can select only 5 pictures"
                                    : ""}
                                </span>
                                <button
                                  style={{
                                    border: "white 2px solid",
                                    borderRadius: "25px",
                                    width: "35px",
                                    backgroundColor: "#FF3131",
                                    color: "white",
                                    fontSize: "15px",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    setError(undefined);
                                    setFieldError(undefined);
                                    setMinFileError(undefined);
                                    setMaxFileError(undefined);
                                  }}
                                >
                                  x
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </form>
            </div>
          </Tab.Container>
        </div>
      </Container>
      {/* <Toaster/> */}
      <ConditionModal
        acceptCondition={() => {
          setSecondCheckbox(!secondCheckbox);
          setFirstCheckbox(!firstCheckbox);
          setOpen(!open);
        }}
        value={firstCheckbox}
        closeModal={() => setOpen(false)}
        isOpen={open}
      />
      <ConfirmModal
        data={emptyfield}
        isopen={modal}
        setisopen={setModal}
        handleClick={submitData}
        setData={setemptyField}
      />
    </div>
  );
}

export default Content;
