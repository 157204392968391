import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import BASE_URL from "../../baseUrl/baseUrl";

const Content = () => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const countryCode = [
    "+1",   // United States
    "+44",  // United Kingdom
    "+92",  // Pakistan
    "+91",  // India
    "+33",  // France
    "+49",  // Germany
    "+81",  // Japan
    "+86",  // China
    "+61",  // Australia
    "+34",  // Spain
    "+39",  // Italy
    "+7",   // Russia
    "+82",  // South Korea
    "+52",  // Mexico
    "+55",  // Brazil
    "+971", // United Arab Emirates
    "+966", // Saudi Arabia
    "+968", // Oman
    "+20",  // Egypt
    "+27",  // South Africa
    "+30",  // Greece
    "+31",  // Netherlands
    "+32",  // Belgium
    "+46",  // Sweden
    "+47",  // Norway
    "+48",  // Poland
    "+54",  // Argentina
    "+56",  // Chile
    "+57",  // Colombia
    "+58",  // Venezuela
    "+60",  // Malaysia
    "+63",  // Philippines
    "+64",  // New Zealand
    "+65",  // Singapore
    "+66",  // Thailand
    "+81",  // Japan
    "+82",  // South Korea
    "+84",  // Vietnam
    "+86",  // China
    "+90",  // Turkey
    "+91",  // India
    "+92",  // Pakistan
    "+93",  // Afghanistan
    "+94",  // Sri Lanka
    "+95",  // Myanmar
    "+98",  // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269"  // Comoros
  ]


  const setUser = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };

  const postData = async () => {
    let emailToSend = email;
    if (selectorValue === 'Mobiel Number') {
      emailToSend = selectorValueCode + email;
    }
    const requestData = {
      password: password,
      email: emailToSend,
    };

    await fetch(`${BASE_URL}/login1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Msg === "login") {
          setUser(data);
        } else {
          setError(true);
          setErrorMsg(data.Msg);
        }
      });
  };


  const images = [
    {
      img: "assets/img/coming-soon/1.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/2.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/3.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  };

  const [selectorValue, setSelectorValue] = useState('')
  const [selectorValueCode, setCountryCode] = useState('')
  const handleChange = (e) => {
    setSelectorValue(e.target.value)
  }

  const handleChangeCode = (e) => {
    console.log(e.target.value)
    setCountryCode(e.target.value)
  }

  return (
    <div className="acr-auth-container">
      <div className="acr-auth-content">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            postData();
          }}
        >
          <div className="auth-text">
            <h3>Log Into Acres</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's
            </p>
          </div>
          <div className="form-group">
            <label>Select Email OR Phone Number</label>
            <select
              value={selectorValue}
              className="form-control form-control-light"
              onChange={handleChange}
            >
              <option>Select</option>
              <option>Email Address</option>
              <option>Mobiel Number</option>
            </select>
          </div>
          {selectorValue === 'Email Address' &&
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control form-control-light"
                placeholder="Email Address"
                name="email"
              />
            </div>}
          {selectorValue === 'Mobiel Number' &&
            <div className="form-group">
              <label>Mobiel Number</label>
              <div style={{ display: 'flex' }}>
                <select value={selectorValueCode} onChange={handleChangeCode} className="form-controlCode form-control-light">
                  {countryCode.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
                <input
                  type="number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control form-control-light"
                  placeholder="Mobiel Number"
                  name="email"
                />
              </div>

            </div>}
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control form-control-light"
              placeholder="Password"
              name="password"
            />
          </div>
          <div className="form-group">
            <Link to="#" className="forgot-password">
              Forgot Password?
            </Link>
          </div>
          <button type="submit" className="btn-custom secondary btn-block">
            Login
          </button>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {error ? (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                  backgroundColor: "#FF3131",
                  color: "white",
                  padding: "10px 20px 10px 20px",
                  borderRadius: "5px",
                  alignItems: "center",
                }}
              >
                <span>{error ? `${errorMsg}` : ""}</span>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    border: "white 2px solid",
                    borderRadius: "30px",
                    width: "40px",
                    backgroundColor: "#FF3131",
                    height: "40px",
                  }}
                  onClick={() => {
                    setError(false);
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      alignItems: "center",
                      marginTop: "3px",
                    }}
                  >
                    x
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="auth-seperator">
            <span>OR</span>
          </div>
          <div className="social-login">
            <button type="button" className="acr-social-login facebook">
              <i className="fab fa-facebook-f" /> Continue with Facebook{" "}
            </button>
            <button type="button" className="acr-social-login google">
              <i className="fab fa-google" /> Continue with Google
            </button>
          </div>
          <p className="text-center mb-0">
            Don't have an account? <Link to="/register">Create One</Link>{" "}
          </p>
        </form>
      </div>
      <div className="acr-auth-bg">
        <Slider className="acr-auth-bg-slider acr-cs-bg-slider" {...settings}>
          {images.map((item, i) => (
            <div key={i}>
              <div
                className="acr-cs-bg-item bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/" + item.img + ")",
                }}
              >
                <div className="acr-auth-quote">
                  <h6>{item.title}</h6>
                  <p>{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Content;
