import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import navigation from "../../data/navigation.json";
import axios from "axios";
import { Menu } from "antd";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import BASE_URL from "../baseUrl/baseUrl";


const Mobilemenu = () => {
  const triggerChild = (e) => {
    let subMenu = "";
    subMenu =
      this.getNextSibling(e.target, ".submenu") !== undefined
        ? this.getNextSibling(e.target, ".submenu")
        : null;
    if (subMenu !== null && subMenu !== undefined && subMenu !== "") {
      subMenu.classList = subMenu.classList.contains("d-block")
        ? "submenu"
        : "submenu d-block";
    }
  };
  const [mainLists, setMainLists] = useState([]);
  const [statuslist, setStatuslist] = useState([]);

  const fetchMainLists = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/NavList/api/mainlists`);
      const data = response?.data.sort((a, b) => a.index - b.index);

      console.log("respoanse.data : ", response.data)

      setMainLists(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [openKeys, setOpenKeys] = useState(['sub1']);
  const onOpenChange = (keys) => {
    console.log(keys)
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    fetchMainLists();
    axios
      .get(`${BASE_URL}/admin/get-categories`)
      .then((res) => {
        console.log("awdawawdwda :", res.data.result)
        setStatuslist(res.data.result);
      });
  }, []);


  const rootSubmenuKeys = ['sub1'];
let datadd = {"Home":"home","About Us":"about"}

let subListRoute = {"Services":"services"}

  return (
    <div className="aside-scroll">
      <div>
        {mainLists && mainLists.length > 0 ? (
          <Menu

            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{
              width: 256,
            }}
          >
            {mainLists.map((mainListItem, index) => {
              return (
                mainListItem.subLists.length === 0 ?
                <Menu.Item key={index}  >
               <Link to={`/${datadd[mainListItem.title]}`}>{mainListItem.title}</Link>
              </Menu.Item>
                :
                <Menu.SubMenu
                key={mainListItem._id}
                title={mainListItem.title}
                onTitleClick={(e) => console.log(e)} >
               { mainListItem.subLists.map((subListItem) => {
                   return (
                   
                    <Menu.Item  key={subListItem._id}  >
                      {subListItem.title}
                    </Menu.Item>
                   )
                   
                  })}
                  </Menu.SubMenu>
              )
            }
            )}
          </Menu>
        ) : null}
      </div>
    </div>
  );
};

export default Mobilemenu;



{/* <ul>
<li className="menu-section-title">Pages</li>
{mainLists.length > 0
  ? mainLists.map((item, i) => (
      <li
        key={i}
        className={`menu-item ${
          item.child ? "menu-item-has-children" : ""
        } `}
        onClick={() => triggerChild}
      >
        {item.child ? (
          <Link onClick={(e) => e.preventDefault()} to="/">
            {" "}
            <i className={"flaticon-" + item.icon + ""} />{" "}
            {item.linkText}{" "}
          </Link>
        ) : (
          <Link to={item.link}>
            {" "}
            <i className={"flaticon-" + item.icon + ""} />{" "}
            {item.title}{" "}
          </Link>
        )}
        {item.child ? (
          <ul className="submenu" role="menu">
            {item.submenu.map((sub_item, i) => (
              <li
                key={i}
                className={`menu-item ${
                  sub_item.child ? "menu-item-has-children" : ""
                } `}
              >
                {sub_item.child ? (
                  <Link onClick={(e) => e.preventDefault()} to="/">
                    {" "}
                    {sub_item.linkText}{" "}
                  </Link>
                ) : (
                  <Link to={sub_item.link}> {sub_item.linkText} </Link>
                )}
                {sub_item.submenu ? (
                  <ul className="submenu">
                    {sub_item.submenu.map((third_item, i) => (
                      <li className="menu-item" key={i}>
                        <Link to={third_item.link}>
                          {third_item.linkText}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    ))
  : null}
{/* Pages End */}
{/* Social Media Start */ }
{/* <li className="menu-section-title">Get Social</li>
<li className="menu-item">
  {" "}
  <Link to="#">
    {" "}
    <i className="flaticon-facebook" />
    Facebook
  </Link>{" "}
</li>
<li className="menu-item">
  {" "}
  <Link to="#">
    {" "}
    <i className="flaticon-linkedin" /> Linkedin{" "}
  </Link>{" "}
</li>
<li className="menu-item">
  {" "}
  <Link to="#">
    {" "}
    <i className="flaticon-twitter" /> Twitter{" "}
  </Link>{" "}
</li>
<li className="menu-item">
  {" "}
  <Link to="#">
    {" "}
    <i className="flaticon-instagram" /> Instagram{" "}
  </Link>{" "}
</li>
</ul>  */}